import React, { useEffect, useState } from 'react';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import "../../styles/newbusiness.css";
import PointsHeader from '../../components/NewBusiness/PointsHeader';
import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { decryptFromStore } from '../../helper/sessionStore';


const BusinessQuizStart = () => {
    const navigate = useNavigate()
    const location = useLocation();
    console.log(" clocation", location.state);
    const [activeUser, setActiveUser] = useState(null);
    const [show, setShow] = useState(false);  // Modal visibility state
    const [showGo, setShowGo] = useState(false);  // Modal visibility state
    const [buttonClicked, setButtonClicked] = useState(false);  // To trigger button animation

    // Handle the button click
    const handleShow = () => {
        setShow(true); // Show the modal when the button is clicked
        setButtonClicked(true); // Trigger the button animation

        // Set a timeout to close the modal after the animation completes
        setTimeout(() => {
            setShow(false);
            handleShowGo() // Close the modal after the animation duration (1.5s + delay)
        }, 1500); // 3.5s to allow both animations (1.5s for "Ready?" and "Go!", plus 1s delay)
    };

    const handleShowGo = () => {
        setShowGo(true); // Show the modal when the button is clicked
        setButtonClicked(true); // Trigger the button animation

        // Set a timeout to close the modal after the animation completes
        setTimeout(() => {
            setShowGo(false);
            navigate("/trail/gamified-quiz-question", {
                state: {
                    step: true,
                    trailId: location?.state?.trailId,
                    name: location?.state?.name,
                }
            }) // Close the modal after the animation duration (1.5s + delay)
        }, 1500); // 3.5s to allow both animations (1.5s for "Ready?" and "Go!", plus 1s delay)
    };

    // Modal close handler (for any other closing actions)
    const handleClose = () => setShow(false);

    useEffect(() => {
        const getUser = decryptFromStore("selectedUser")
        if(getUser?.user) {
            setActiveUser(getUser.user)
        } else {
            navigate("/trail/gamified-quiz", {
                state: {
                    step: true,
                    trailId: location?.state?.trailId,
                    name: location?.state?.name,
                }
            }) // Redirect to sign-in page if no user is selected yet
        }
        sessionStorage.removeItem("gamifiedData")
        sessionStorage.removeItem("joker")
        localStorage.removeItem("timeLeft")
    },[])


    return (
        <>
            <Header />
            <main className='business_quiz_start'>
                <section>
                    <PointsHeader to="/trail/points" />
                </section>
                <section className='business_puzzle '>
                    <div className="business_quiz_start_home business_choose_quiz position-relative">
                        <div className="container-fluid">
                            <div>
                                <div className='d-flex justify-content-between justify-content-md-evenly align-items-center choose_quiz_user_main_box'>
                                    {activeUser === "Ada" ?
                                        <div className='choose_quiz_user_image'>
                                            <div className="choose_quiz_user_image_circle">
                                                <img src="/new-business/ada.webp" className='position-relative z-3' alt="Ada" />
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <button className='border-0 ada_btn'>Ada (You)</button>
                                            </div>
                                        </div> :
                                        <div className='choose_quiz_user_image'>
                                            <div className="choose_quiz_user_image_circle">
                                                <img src="/new-business/ommo.webp" className='position-relative z-3' alt="ommo" />
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <button className='border-0 ada_btn'>Ommo (You)</button>
                                            </div>
                                        </div>
                                    }
                                    <div className='choose_quiz_user_image'>
                                        <div className="choose_quiz_user_image_circle">
                                        { activeUser === "Ada" ? <img src="/new-business/ommo.webp" alt="Ommo" /> :
                                             <img src="/new-business/ada.webp" className='position-relative z-3' alt="Ada" />
                                        }
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <button className='border-0 ommo_btn'> {activeUser === "Ada" ? "Ommo" : "Ada" }</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className={`border-0 quiz_start_btn ${buttonClicked ? 'animate' : ''}`}
                                onClick={handleShow}
                            >Start</button>
                        </div>
                    </div>
                </section>

                <Modal className='start_quiz_modal' show={show} centered onHide={handleClose}>
                    <Modal.Body className='d-flex justify-content-center p-0'>
                        <div className='start_quiz_modal_text'>
                            Ready?
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal className='start_quiz_modal' show={showGo} centered onHide={() => setShowGo(false) }>
                    <Modal.Body className='d-flex justify-content-center p-0'>
                        <div className='start_quiz_modal_text'>
                            Go!
                        </div>
                    </Modal.Body>
                </Modal>


            </main>
            <Footer />

        </>
    );
};

export default BusinessQuizStart
