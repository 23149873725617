import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, ProgressBar } from "react-bootstrap";
import { decryptFromStore } from "../../helper/sessionStore";
import { getServerURL } from "../../helper/envConfig";
import api from "../../helper/api";
import PointsHeader from "../../components/NewBusiness/PointsHeader";

const BusinessCourseResults = () => {
    const serverURL = getServerURL();
    const navigate = useNavigate();
    const location = useLocation();
    const [caseStudies, setCaseStudies] = useState([]);
    const [progressData, setProgressData] = useState({});
    const [QuizAns, setQuizAns] = useState({});
    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);


    // const caseStudies = [
    //     {
    //         title: "Course",
    //         description:
    //             "Finish up with 3 engaging courses in data privacy to round out your training.",
    //         imageSrc: "/new-business/course.webp",
    //     },
    //     {
    //         title: "Puzzle",
    //         description:
    //             "Complete the puzzle to reinforce your understanding of privacy fundamentals.",
    //         imageSrc: "/new-business/puzzle.webp",
    //     },
    //     {
    //         title: "Gamified Quiz",
    //         description:
    //             "Ready for a challenge? Test your knowledge in a quiz game! Answer 7 out of 10 questions correctly to progress and earn points.",
    //         imageSrc: "/new-business/gamified.webp",
    //     },
    // ];

    const getSteps = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}trails/steps/`
            );
            setCaseStudies(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSteps();
    }, []);

    const getResults = () => {
        const result = decryptFromStore("businessQuizAnswer");
        if(result?.points > 0) {
            setShow(true);
        }
        setQuizAns(result);
        setTimeout(() => {
            handleClose()
        }, 1500);
    };

    const getProgressStatus = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}trails/step-status/?trail_id=${location?.state?.trailId}`
            );
            setProgressData(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getResults();
        getProgressStatus();
    }, []);

    const formatDuration = (timeString) => {
        if (typeof timeString !== "string" || timeString.length === 0) {
            // Handle invalid input
            return "Invalid time";
        }

        const parts = timeString.split(":").map((part) => parseInt(part, 10));
        const hours = parts[0];
        const minutes = parts[1];
        const seconds = parts.length > 2 ? parts[2] : 0;

        let formattedTime = "";

        if (hours > 0) {
            formattedTime += `${hours} hour${hours > 1 ? "s" : ""} `;
        }
        if (minutes > 0 || (hours > 0 && seconds > 0)) {
            formattedTime += `${minutes} minute${minutes !== 1 ? "s" : ""} `;
        }
        if (seconds > 0) {
            formattedTime += `${seconds} second${seconds !== 1 ? "s" : ""}`;
        }

        return formattedTime.trim();
    };

     console.log("location",location)
    const handleNavigate = (path) => {
        sessionStorage.removeItem("businessCourse");
        navigate(path, {
            state: {
                step: true,
                trailId: location?.state?.trailId,
                name: location?.state?.name,
            }
        });
    };

    return (
        <>
            <Header />
            <section>
                    <PointsHeader to="/trail/points" />
            </section>
            <main className="business_course_results business_public_spaces business_results">
                <section>
                    <div className="featured-main">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-12 col-lg-8 col-xl-7">
                                    <h3>
                                       {QuizAns?.courseName}
                                    </h3>
                                    <div className="card linear-gradient girlimge rounded-3 border-0 position-relative">
                                        <div className="card-body px-4 pt-5">
                                            <div className="d-flex justify-content-center">
                                                <img
                                                    src="/featured-courses/girl-party.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="text-center pt-4 pt-md-5 fw-600 text-black">
                                                You{" "}
                                                {QuizAns?.score >= 80
                                                    ? "Passed"
                                                    : "Failed"}{" "}
                                            </h6>
                                            <h2 className="text-center">
                                                {QuizAns?.score >= 80
                                                    ? "Pass"
                                                    : "Fail"}{" "}
                                                with {QuizAns?.score}% Score
                                            </h2>
                                            <p className="text-center text-black text-black pt-4 fs-5 mb-0 fw-600">
                                                Time taken
                                            </p>

                                            <div className="row justify-content-center g-0">
                                                <div className="col-12 col-md-7 col-lg-7 col-xl-6">
                                                    <button
                                                        type="button"
                                                        className="btn-explore p-sm-15 text-white text-center rounded-2 w-100 mt-2 mb-3"
                                                    >
                                                        {formatDuration(
                                                            QuizAns?.taken_time
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h3 className="fw-500 mb-4">
                                        You got {QuizAns?.points} out of 10
                                        questions right!
                                    </h3>
                                    <h6 className="fw-400 mb-3">
                                        More details on the grade
                                    </h6>
                                    <p className="text-center text-black fs-6 mb-0 fw-400">
                                        You got it {QuizAns?.score}% right. The
                                        answer was well written and had all the
                                        basic concepts of the fraud prevention.
                                        You only missed out on...
                                    </p>

                                    <div className="business_results_right text-center px-0">
                                        <div className="d-flex justify-content-center align-items-center gap-2">
                                            <h3 className="mb-0 points_earned">
                                                Points earned:
                                            </h3>
                                            <div className="business_data_privacy_trail">
                                                <button className="award_btn fw-700 border-0 d-flex align-items-center gap-2">
                                                    <img
                                                        src="/new-business/award-icons.webp"
                                                        width={24}
                                                        alt=""
                                                    />
                                                    {QuizAns?.points}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-3 m-auto mt-4 mt-md-5">
                                        <button
                                            className="replay"
                                            onClick={() => {
                                                handleNavigate("/trail/course");
                                            }}
                                        >
                                            Replay
                                        </button>
                                        <button
                                            onClick={()=> handleNavigate("/trail/puzzle")}
                                            className="start_courses_btn d-flex justify-content-between align-items-center gap-4"
                                        >
                                            Start Puzzle
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 6.99854H21L16 1"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M21 6.99805L16 12.9995"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center mt-4 still_questions">
                                        <span>
                                            Still have questions?{" "}
                                            <Link to="/trail/ask-to-ai" state={{step: true, trailId: location?.state?.trailId, name: location?.state?.name}}>
                                                Click here.
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-11">
                                    <div className="text-start mt-3 mt-lg-5 trail_progressbar">
                                        <h2 className="mb-2 mb-lg-3 text-start">
                                            Data Privacy Trail Progress
                                        </h2>
                                        <span className="mb-3 d-inline-block">
                                            {progressData?.total_complete_step}/
                                            {progressData?.total_step} Completed
                                        </span>
                                        <ProgressBar
                                            now={
                                                (progressData?.total_complete_step /
                                                    progressData?.total_step) *
                                                100
                                            }
                                        />
                                    </div>

                                    <div>
                                        <div className="next_steps_title">
                                            <p className="text-start mt-4 mb-3 mt-lg-5 mb-lg-4">
                                                Next Steps:
                                            </p>
                                        </div>
                                        <div className="row g-4 course_cards">
                                            {caseStudies
                                                ?.slice(2)
                                                ?.map((caseStudy, index) => (
                                                    <div
                                                        className="col-12"
                                                        key={index}
                                                    >
                                                        <div className="card border-0 rounded-3">
                                                            <div className="card-body">
                                                                <div className="d-flex align-items-start align-content-md-center gap-4 flex-column flex-md-row text-start">
                                                                    <img
                                                                        src={
                                                                            caseStudy.icon
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    <div>
                                                                        <h6 className="text-start">
                                                                            {
                                                                                caseStudy.name
                                                                            }
                                                                        </h6>
                                                                        <p className="mb-2 text_light_100">
                                                                            {
                                                                                caseStudy.description
                                                                            }
                                                                        </p>
                                                                        <div className="d-flex align-items-center gap-3">
                                                                            <p className="mb-0 text_light_200 fs-14 fw-600">
                                                                                You
                                                                                will
                                                                                earn:
                                                                            </p>
                                                                            <div className="business_data_privacy_trail">
                                                                                {caseStudy.title !==
                                                                                    "Puzzle" && (
                                                                                    <button className="award_btn fw-700 border-0 d-flex align-items-center gap-2">
                                                                                        <img
                                                                                            src="/new-business/award-icons.webp"
                                                                                            width={
                                                                                                24
                                                                                            }
                                                                                            alt=""
                                                                                        />
                                                                                        {/* {caseStudy.awardCount} */}
                                                                                        {
                                                                                            caseStudy?.points
                                                                                        }
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Modal
                className="congratulations_modal_custom"
                show={show}
                centered
                size="lg"
                onHide={handleClose}
            >
                <Modal.Body>
                    <div className="text-center">
                        <div className="position-relative correct_answers d-flex justify-content-center m-auto">
                            <img
                                src="/new-business/points.webp"
                                className="m-auto d-flex justify-content-center"
                                alt=""
                            />
                            <h5>{QuizAns?.points}</h5>
                        </div>
                        <h3>Congratulations!</h3>
                        <p>+{QuizAns?.points} Points for Completing a Course!</p>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    );
};

export default BusinessCourseResults;
