// Route-related imports
import { Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

// Component imports
import React from 'react';



import '../assets/font/font-family-Poppins.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/App.css';


import Index from '../page/Index'
import Courses from '../page/CourseList'
// import CategoryCourse from '../page/CategoryCourse';
import IndividualPricing from '../page/IndividualPricing';
import Teams from '../page/Teams';
import SignUp from '../components/SignUp';
import BusinessOwnersDetails from '../components/BusinessOwnersDetails';
import BusinessUserDetails from '../components/register/BusinessUserDetails';
import BusinessSubscription from '../components/BusinessSubscription';
import BusinessPayment from '../components/BusinessPayment';
import Complete from '../components/Complete';
import Complete2 from '../components/Complete2';
import Subscription from '../components/Subscription';
import SelectSubscription from '../components/SelectSubscription';
import Payment from '../components/Payment';
import PaymentProcessed from '../components/PaymentProcessed';
import SignIn from '../components/login/SignIn';
import FeaturedDetails from '../page/FeaturedDetails';
import FeaturedQuize1 from '../page/FeaturedQuize1';
import FeaturedQuize2 from '../page/FeaturedQuize2';
import QuizeResults from '../components/QuizeResults';
import BusinessDashbaordHome from '../page/BusinessDashbaordHome';
import BusinessProgress from '../page/BusinessProgress';
import CommunityAnnouncements from '../page/CommunityAnnouncements';
import FaqQuestions from '../page/FaqQuestions';
import CommunityHelp from '../page/CommunityHelp';
import CommunityHelpCenterTopic from '../page/CommunityHelpCenterTopic';
import BusinessBookmarks from '../page/BusinessBookmarks';
import BusinessLeaderboard from '../page/BusinessLeaderboard';
import BusinessCourses from '../page/BusinessCourses';
import BusinessQuizzes from '../page/BusinessQuizzes';
import BusinessLearningGroups from '../page/BusinessLearningGroups';
import BusinessGroupView from '../page/BusinessGroupView';
import BusinessAnalytics from '../page/BusinessAnalytics';
import BusinessSettings from '../page/BusinessSettings';
import CustomerHome from '../page/CustomerHome';
import CustomerMyProgress from '../page/CustomerMyProgress';
import CustomerBookmarks from '../page/CustomerBookmarks';
import CustomerLeaderboard from '../page/CustomerLeaderboard';
import CustomerCourses from '../page/CustomerCourses';
import CustomerQuizzes from '../page/CustomerQuizzes';
import ContentHome from '../page/ContentHome';
import ContentUpload from '../page/ContentUpload';
import ContentPreview from '../page/ContentPreview';
import ContentQuestions from '../page/ContentQuestions';
import ContentMultifileQuizzes from '../page/ContentMultifileQuizzes';
import ContentCourses from '../page/ContentCourses';
import ContentQuizzes from '../page/ContentQuizzes';
import CoursesExplore from '../page/CoursesExplore';
import CourseLevel from '../page/CourseLevel';
// import CategoryExplore from '../page/CategoryExplore';
import GoldCourses from '../page/GoldCourses';
import Compilance from '../page/Compilance';
import UsersSignUp from '../components/UsersSignUp';
import CeoManager from '../page/CeoManager';
import ComplianceOfficer from '../page/ComplianceOfficer';
import Dpos from '../page/Dpos';
import Employe from '../page/Employe';
import SriLankaCourse from '../page/SriLankaCourse';
import CourseDetails from '../page/CourseDetails';
import SrilLankaCourseQuize1 from '../page/SrilLankaCourseQuize1';
import SrilLankaCourseQuize2 from '../page/SrilLankaCourseQuize2';
import FeaturedGoldCourse from '../page/FeaturedGoldCourse';
import FeaturedGoldCourseDetails from '../page/FeaturedGoldCourseDetails';
import FeaturedGoldCourseQuize1 from '../page/FeaturedGoldCourseQuize1';
import FeaturedGoldCourseQuize2 from '../page/FeaturedGoldCourseQuize2';
import CustomerHomeExplore from '../page/CustomerHomeExplore';
import { ProtectedLogin, ProtectedQuize, ProtectedQuizeresult, ProtectedRole } from './ProtectedQuize';
import Otp from '../page/Otp';
import ProtecdtedOtp from './ProtecdtedOtp';
import BulkUpload from '../page/BulkUpload';
import AssignCourse from '../page/AssignCourse';
import BusinessCompliance from '../page/BusinessCompliance';
import EmployeeProfile from '../page/EmployeeProfile';
import EmployeesProgress from '../page/EmployeesProgress';
import EmployeeReport from '../page/EmployeeReport';
import BusinessFamily from '../page/BusinessFamily';
import ChangePassword from '../components/login/ChangePassword';
import EditProfile from '../page/EditProfile';
import UpdateAssociatedCode from '../components/login/UpdateAssociatedCode';
import PrivateCourse from './PrivateCourse';
import DataPrivacy from '../page/DataPrivacy';
import Imprint from '../page/Imprint';
import TermsAndCondition from "../page/TermsAndCondition"
import ParentDetail from '../page/ParentDetails';
import ParentApproval from '../page/ParentApproval';
import RejectKid from '../page/RejectKid';
import KidsAgeConfirm from '../page/KidsAgeConfirm';
import Feedback from '../components/Feedback';
import Family from '../page/Family';
import AdminDashboard from '../page/admin_flow/AdminDashboard';
import AdminUserManagement from '../page/admin_flow/AdminUserManagement';
import AdminCourseManagement from '../page/admin_flow/AdminCourseManagement';
import AdminAnalytics from '../page/admin_flow/AdminAnalytics';
import UserProfileManagement from '../page/admin_flow/UserProfileManagement';
import AdminFamily from '../page/admin_flow/AdminFamily';
import AdminFeedback from '../page/admin_flow/AdminFeedback';
import AdminGrades from '../page/admin_flow/AdminGrades';
// import AdminEmpty from '../page/admin_flow/AdminEmpty';
import AdminPerformanceScale from '../page/admin_flow/AdminPerformanceScale';
import CourseCreationWizard from '../page/admin_flow/CourseCreationWizard';
import Adminsettings from '../page/admin_flow/Adminsettings';
import Compliance from '../page/admin_flow/Compliance';
import AdminLogin from '../components/Admin/AdminLogin';
import AdminMarketing from '../page/admin_flow/AdminMarketing';
import AdminSales from '../page/admin_flow/AdminSales';
import AdminSystem from '../page/admin_flow/AdminSystem';
import AdminLegalDocumentEditor from '../page/admin_flow/AdminLegalDocumentEditor';
import TestEnvironmentWelcome from '../page/Business/TestEnvironmentWelcome';
import BusinessUserPlans from '../page/Business/BusinessUserPlans';
import BusinessUserSupportPlans from '../page/Business/BusinessUserSupportPlans';
import BusinessUserSharedCourses from '../page/Business/BusinessUserSharedCourses';
import BusinessUserPayment from '../page/Business/BusinessUserPayment';
import BusinessSetting from '../page/Business/BusinessSetting';
import PaymentDetails from '../page/Individual/PaymentDetails';
// import FamilyUserFreeTrails from '../page/Family/FamilyUserFreeTrails';
import FamilyUserPaymentDetails from '../page/Family/FamilyUserPaymentDetails';
import BusinessUserCourseTrail from '../page/Business/BusinessUserCourseTrail';
import BusinessUserBlendedLearning from '../page/Business/BusinessUserBlendedLearning';
import BusinessUserIndividualizedCourses from '../page/Business/BusinessUserIndividualizedCourses';
import StripeRedirect from '../components/StripeRedirect';
import PaymentProtect from './PaymentProtect';
import FamilyUserPlans from '../page/Family/FamilyUserPlans';
import FamilyUserBlendedLearning from '../page/Family/FamilyUserBlendedLearning';
import BusinessLandingPage from '../page/NewBusiness/BusinessLandingPage';
import BusinessDataPrivacyTrail from '../page/NewBusiness/BusinessDataPrivacyTrail';
import BusinessPoints from '../page/NewBusiness/BusinessPoints';
import BusinessPublicSpaces from '../page/NewBusiness/BusinessPublicSpaces';
import BusinessCaseStudyQuestion from '../page/NewBusiness/BusinessCaseStudyQuestion';
import BusinessCaseStudiesResults from '../page/NewBusiness/BusinessCaseStudiesResults';
import BusinessPuzzle from '../page/NewBusiness/BusinessPuzzle';
import BusinessQuizChooseGuide from '../page/NewBusiness/BusinessQuizChooseGuide';
import BusinessQuizStart from '../page/NewBusiness/BusinessQuizStart';
import BusinessQuiz from '../page/NewBusiness/BusinessQuiz';
import BusinessQuizResults from '../page/NewBusiness/BusinessQuizResults';
import BusinessDataPrivacyTrailResults from '../page/NewBusiness/BusinessDataPrivacyTrailResults';
import BusinessUnansweredQuestions from '../page/NewBusiness/BusinessUnansweredQuestions';
// import BusinessQuestions from '../page/NewBusiness/BusinessCourseQuiz';
import BusinessCourseResults from '../page/NewBusiness/BusinessCourseResults';
import BusinessCourse from '../page/NewBusiness/BusinessCourse';
import BusinessCourseQuiz from '../page/NewBusiness/BusinessCourseQuiz';
import AdminUserPoints from '../page/admin_flow/AdminUserPoints';
import AdminFlaggedQuestions from '../page/admin_flow/AdminFlaggedQuestions';
import BusinessFacts from '../page/NewBusiness/BusinessFacts';
import BusinessFactsDetails from '../page/NewBusiness/BusinessFactsDetails';
import WhatDidYouLearn from '../page/NewBusiness/WhatDidYouLearn';
import Certificate from '../page/NewBusiness/Certificate';
import WhatsOpenAi from '../page/NewBusiness/WhatsOpenAi';
import TransferJob from '../page/NewBusiness/TransferJob';
import SuggestFutureTrainingTopics from '../page/NewBusiness/SuggestFutureTrainingTopics';
import ShareYourFeedback from '../page/NewBusiness/ShareYourFeedback';
import Trails from '../page/NewBusiness/Trails';
import RequestTraining from '../page/NewBusiness/RequestTraining';
import RequestLegalAdvice from '../page/NewBusiness/RequestLegalAdvice';
import RequestLegalAdviceForm from '../page/NewBusiness/RequestLegalAdviceForm';
import ComplianceReportingDashboard from '../page/ComplianceReportingDashboard';
import RequestOnlineTraining from '../page/NewBusiness/RequestOnlineTraining';
import RequestOnsiteTraining from '../page/NewBusiness/RequestOnsiteTraining';
import InformationSecurity from '../page/InformationSecurity';
export default [

  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/courses",
    element: <Courses />,
  },
  // {
  //   path: "/category",
  //   element: <CategoryCourse />,
  // },
  {
    path: "/individuals",
    element: <IndividualPricing />,
  },
  {
    path: "/teams",
    element: <Teams />,
  },
  {
    path: "/announcements",
    element: <CommunityAnnouncements />,
  },
  {
    path: "/faq-questions",
    element: <FaqQuestions />,
  },
  {
    path: "/featured-details",
    element: <FeaturedDetails />,
  },

  {
    path: "/quiz",
    element: <ProtectedQuize> <FeaturedQuize1 /> </ProtectedQuize>,
  },

  {
    path: "/featured-quize-1",
    element: <FeaturedQuize1 />,
  },
  {
    path: "/featured-quize-2",
    element: <FeaturedQuize2 />,
  },
  {
    path: "/feedback",
    element: <Feedback />,
  },
  {
    path: "/quize-results",
    element: <ProtectedQuizeresult><QuizeResults /></ProtectedQuizeresult>,
  },
  {
    path: "/sign-up",
    element: <ProtectedLogin> <SignUp /> </ProtectedLogin>,
  },
  {
    path: "/sign-in",
    element: <ProtectedLogin> <SignIn /> </ProtectedLogin>,
  },
  {
    path: "/business-owners-details",
    element: <BusinessOwnersDetails />,
  },
  {
    path: "/user-details",
    element: (
      <ProtectedLogin>
        <ProtectedRole>
          <BusinessUserDetails />
        </ProtectedRole>
      </ProtectedLogin>
    ),
  },
  {
    path: "/business-subscription",
    element: <BusinessSubscription />,
  },
  {
    path: "/business-payment",
    element: <BusinessPayment />,
  },
  {
    path: "/complete",
    element: <Complete />,
  },
  {
    path: "/complete2",
    element: <Complete2 />,
  },
  {
    path: "/subscription",
    element: <Subscription />,
  },
  {

    path: "/select-subscription",
    element: <SelectSubscription />,
  },
  {
    path: "/payment",
    element: <Payment />,
  },

  {
    path: "/processed",
    element: <PaymentProtect role={[2, 12, 13]} >  <PaymentProcessed /> </PaymentProtect>,
  },
  {
    path: "/community-help",
    element: <CommunityHelp />,
  },
  {
    path: "/community-help-center-topic",
    element: <CommunityHelpCenterTopic />,
  },
  {
    path: "/business-home",
    element: <PrivateRoute role={1}> <BusinessDashbaordHome /> </PrivateRoute>,
  },
  {
    path: "/business-progress",
    element: <BusinessProgress />,
  },
  {
    path: "/business-bookmarks",
    element: <BusinessBookmarks />,
  },
  {
    path: "/business-leaderboard",
    element: <BusinessLeaderboard />,
  },
  {
    path: "/business-courses",
    element: <PrivateCourse> <BusinessCourses /> </PrivateCourse>,
  },
  {
    path: "/business-quizzes",
    element: <BusinessQuizzes />,
  },
  {
    path: "/business-learning-groups",
    element: <BusinessLearningGroups />,
  },
  {
    path: "/business-group-view",
    element: <BusinessGroupView />,
  },
  {
    path: "/business-analytics",
    element: <BusinessAnalytics />,
  },
  {
    path: "/business-settings",
    element: <BusinessSettings />,
  },
  {
    path: "/business-compliance",
    element: <BusinessCompliance />,
  },
  {
    path: "/employees-progress",
    element: <EmployeesProgress />,
  },
  {
    path: "/employee-profile",
    element: <EmployeeProfile />,
  },
  {
    path: "/employee-report",
    element: <EmployeeReport />,
  },
  // {
  //   path: "/business-family",
  //   element: <PrivateRoute role={6}>  <BusinessFamily /> </PrivateRoute>,
  // },
  {
    path: "/business-family",
    element: <BusinessFamily />,
  },
  {
    path: "/family",
    element: <Family />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/edit-profile",
    element: <PrivateCourse> <EditProfile /> </PrivateCourse>,
  },
  {
    path: "/update-associated-code",
    element: <UpdateAssociatedCode />,
  },
  {
    path: "/customer-home",
    element: <PrivateRoute role={2}> <CustomerHome /> </PrivateRoute>,
  },
  {
    path: "/customer-home-explore",
    element: <CustomerHomeExplore />,
  },
  {
    path: "/customer-my-progress",
    element: <CustomerMyProgress />,
  },
  {
    path: "/customer-bookmarks",
    element: <CustomerBookmarks />,
  },
  {
    path: "/customer-leaderboard",
    element: <CustomerLeaderboard />,
  },
  {
    path: "/customer-courses",
    element: <PrivateCourse> <CustomerCourses /> </PrivateCourse>,
  },
  {
    path: "/customer-quizzes",
    element: <CustomerQuizzes />,

  },

  {
    path: "/conten-home",
    element: <ContentHome />,
  },
  {
    path: "/conten-upload",
    element: <ContentUpload />,
  },
  {
    path: "/conten-preview",
    element: <ContentPreview />,
  },

  {
    path: "/conten-questions",
    element: <ContentQuestions />,
  },
  {

    path: "/content-uploader-quizzes-multifile-preview",
    element: <ContentMultifileQuizzes />,
  },
  {
    path: "/content-courses",
    element: <ContentCourses />,
  },
  {
    path: "/content-quizzes",
    element: <ContentQuizzes />,
  },
  // {
  //   path: "/courses-category",
  //   element: <CoursesExplore />,
  // },
  {
    path: "/courses-category/:slug",
    element: <CoursesExplore />,
  },
  {
    path: "/courses-level",
    element: <CourseLevel />,
  },

  {
    path: "/courses-explore",
    element: <CoursesExplore />,
  },

  // {
  //   path: "/courses-explore",
  //   element: <CategoryExplore />,
  // },

  {

    path: "/gold-courses",
    element: <GoldCourses />,
  },
  {
    path: "/compilance",
    element: <Compilance />,
  },


  {
    path: "/users-sign-up",
    element: <ProtectedLogin>
      <ProtectedRole>
        <UsersSignUp />
      </ProtectedRole>
    </ProtectedLogin>,
  },
  {

    path: "/manager-ceo",
    element: <CeoManager />,
  },
  {
    path: "/compliance-officer",
    element: <ComplianceOfficer />,
  },
  {
    path: "/dpos",
    element: <Dpos />,
  },
  {

    path: "/employe",
    element: <Employe />,
  },

  {
    path: "/sri-lanka-course",
    element: <SriLankaCourse />,
  },
  {
    path: "/course-details/:id",
    element: <CourseDetails />,
  },
  {

    path: "/sri-lanka-course-quize-1",
    element: <SrilLankaCourseQuize1 />,
  },
  {
    path: "/sri-lanka-course-quize-2",
    element: <SrilLankaCourseQuize2 />,
  },
  {
    path: "/gold-course",
    element: <FeaturedGoldCourse />,
  },
  {
    path: "/gold-course-details",
    element: <FeaturedGoldCourseDetails />,
  },
  {

    path: "/gold-course-quize-1",
    element: <FeaturedGoldCourseQuize1 />,
  },
  {
    path: "/gold-course-quize-2",
    element: <FeaturedGoldCourseQuize2 />,
  },
  {
    path: "/bulk-upload",
    element: <BulkUpload />,
  },
  {
    path: "/assign-course",
    element: <AssignCourse />,
  },

  {
    path: "/verify-email",
    element: <ProtecdtedOtp> <Otp /> </ProtecdtedOtp>,
  },

  {
    path: "/kids-age-confirm",
    element: <ProtecdtedOtp> <KidsAgeConfirm /> </ProtecdtedOtp>,
  },

  {
    path: "/parent-details",
    element: <ProtecdtedOtp> <ParentDetail /> </ProtecdtedOtp>,
  },

  {
    path: "/parent-approve",
    element: <ParentApproval />,
  },

  {
    path: "/parentsshouldallowkidstobepartofunicorso",
    element: <RejectKid />,
  },

  {
    path: "/data-privacy",
    element: <DataPrivacy />,

  },

  {
    path: "/imprint",
    element: <Imprint />,
  },
  {
    path: "/terms-condition",
    element: <TermsAndCondition />,
  },


  // Admin routes start
  {
    path: "/admin-dashboard",
    element: <PrivateRoute role={14}> <AdminDashboard /> </PrivateRoute>,
  },
  {
    path: "/admin-user-management",
    element: <AdminUserManagement />,
  },
  {
    path: "/user-profile-management",
    element: <UserProfileManagement />,
  },
  {
    path: "/admin-course-management",
    element: <AdminCourseManagement />,
  },
  {
    path: "/admin-analytics",
    element: <AdminAnalytics />,
  },
  {
    path: "/admin-family",
    element: <AdminFamily />,
  },

  {
    path: "/admin-feedback",
    element: <AdminFeedback />,
  },

  {
    path: "/admin-grades",
    element: <AdminGrades />,
  },
  {
    path: "/admin-performance-scale",
    element: <AdminPerformanceScale />,
  },
  {
    path: "/admin-setting",
    element: <Adminsettings />,
  },
  {
    path: "/admin-compliance",
    element: <Compliance />,
  },
  {
    path: "/admin-marketing",
    element: <AdminMarketing />,
  },
  {
    path: "/admin-login",
    element: <AdminLogin />,
  },

  {
    path: "/admin-course-creation-wizard",
    element: <CourseCreationWizard />,
  },
  {
    path: "/admin-sales",
    element: <AdminSales />,
  },
  {
    path: "/admin-system",
    element: <AdminSystem />,
  },
  {
    path: "/admin-documentation",
    element: <AdminLegalDocumentEditor />,
  },

  {
    path: "/admin-user-points",
    element: <AdminUserPoints />,
  },

  {
    path: "/admin-flagged-questions",
    element: <AdminFlaggedQuestions />,
  },

  // {
  //   path: "/admin-Empty",
  //   element: <AdminEmpty />,
  // },


  // Admin routes end  

  // Business user start
  {
    path: "/business-free-environment",
    element: <PaymentProtect role={[12]} > <TestEnvironmentWelcome /> </PaymentProtect>,
  },

  {
    path: "/subscription-plans",
    element: <PaymentProtect role={[12, 2]} > <BusinessUserPlans /> </PaymentProtect>,
  },

  {
    path: "/business-support-plans",
    element: <PaymentProtect role={[12]} > <BusinessUserSupportPlans /> </PaymentProtect>,
  },

  {
    path: "/business-shared-courses",
    element: <PaymentProtect role={[12]} > <BusinessUserSharedCourses /> </PaymentProtect>,
  },

  {
    path: "/business-user-payment",
    element: <PaymentProtect role={[12]} > <BusinessUserPayment /> </PaymentProtect>,
  },

  {
    path: "/user-setting",
    element: <PaymentProtect role={[2, 12, 13]} > <BusinessSetting /> </PaymentProtect>,
  },

  // {
  //   path: "/business-individualized-courses",
  //   element: <BusinessUserSelectcourse />,
  // },

  {
    path: "/course-trails",
    element: <PaymentProtect role={[2, 12, 13]} > <BusinessUserCourseTrail /> </PaymentProtect>,
  },
  {
    path: "/business-individualized-course",
    element: <PaymentProtect role={[12]} > <BusinessUserIndividualizedCourses /> </PaymentProtect>,
  },
  {
    path: "/business-blended-learning",
    element: <PaymentProtect role={[12]} > <BusinessUserBlendedLearning /> </PaymentProtect>,
  },

  // Business user end
  // Individual user start

  {
    path: "/individual-payment-details",
    element: <PaymentProtect role={[2]} > <PaymentDetails /> </PaymentProtect>,
  },
  // Individual user end
  // Family user start


  {
    path: "/family-user-plan",
    element: <PaymentProtect role={[13]} >  <FamilyUserPlans /> </PaymentProtect>,
  },

  {
    path: "/family-blended-learning",
    element: <PaymentProtect role={[13]} > <FamilyUserBlendedLearning />  </PaymentProtect>,
  },

  {
    path: "/family-user-payment-details",
    element: <PaymentProtect role={[13]} > <FamilyUserPaymentDetails /> </PaymentProtect>,
  },
  {
    path: "/stripe-redirect",
    element: <PaymentProtect role={[13, 12, 2]} > <StripeRedirect /> </PaymentProtect>,
  },
  // Family user end
  // New Business Flow start
  {
    path: "/trail-list",
    element: <Trails />,
  },

  {
    path: "/trails",
    element: <BusinessLandingPage />,
  },

  {
    path: "/trail/overview-step",
    element: <PrivateRoute> <BusinessDataPrivacyTrail /> </PrivateRoute>,
  },

  {
    path: "/trail/case-study",
    element: <PrivateRoute> <BusinessPublicSpaces /> </PrivateRoute>,
  },

  {
    path: "/trail/points",
    element: <PrivateRoute> <BusinessPoints /></PrivateRoute>
  },

  {
    path: "/trail/case-study-question",
    element: <PrivateRoute>  <BusinessCaseStudyQuestion /> </PrivateRoute>
  },

  {
    path: "/trail/case-studies-results",
    element: <PrivateRoute> <BusinessCaseStudiesResults /> </PrivateRoute>
  },

  {
    path: "/trail/course",
    element: <PrivateRoute> <BusinessCourse /> </PrivateRoute>
  },

  {
    path: "/trail/course-quiz",
    element: <PrivateRoute> <BusinessCourseQuiz /> </PrivateRoute>
  },

  {
    path: "/trail/course-result",
    element: <PrivateRoute> <BusinessCourseResults /> </PrivateRoute>
  },

  {
    path: "/trail/puzzle",
    element: <PrivateRoute> <BusinessPuzzle /> </PrivateRoute>
  },

  {
    path: "/trail/gamified-quiz",
    element: <PrivateRoute> <BusinessQuizChooseGuide /> </PrivateRoute>
  },

  {
    path: "/trail/gamified-quiz-start",
    element: <PrivateRoute> <BusinessQuizStart /> </PrivateRoute>
  },

  {
    path: "/trail/gamified-quiz-question",
    element: <PrivateRoute> <BusinessQuiz /> </PrivateRoute>
  },

  {
    path: "/trail/gamified-quiz-results",
    element: <PrivateRoute> <BusinessQuizResults /> </PrivateRoute>
  },

  {
    path: "/trail/trail-results",
    element: <PrivateRoute> <BusinessDataPrivacyTrailResults /> </PrivateRoute>
  },

  {
    path: "/trail/ask-to-ai",
    element: <PrivateRoute> <BusinessUnansweredQuestions /> </PrivateRoute>
  },

  {
    path: "/trail/facts",
    element: <PrivateRoute> <BusinessFacts /> </PrivateRoute>
  },

  {
    path: "/trail/fact/details",
    element: <PrivateRoute> <BusinessFactsDetails /> </PrivateRoute>
  },

  {
    path: "/trail/what-did-you-learn",
    element: <PrivateRoute> <WhatDidYouLearn /> </PrivateRoute>
  },

  {
    path: "/trail/what-did-you-learn/certificate",
    element: <PrivateRoute> <Certificate /> </PrivateRoute>
  },

  {
    path: "/trail/whats-open",
    element: <PrivateRoute> <WhatsOpenAi /> </PrivateRoute>
  },

  {
    path: "/trail/apply-knowledge-to-real-life",
    element: <PrivateRoute> <TransferJob /> </PrivateRoute>
  },

  {
    path: "/trail/suggest-future-training-topics",
    element: <PrivateRoute> <SuggestFutureTrainingTopics /> </PrivateRoute>
  },

  {
    path: "/trail/share-your-feedback",
    element: <PrivateRoute> <ShareYourFeedback /> </PrivateRoute>
  },

  {
    path: "/request/training",
    element: <RequestTraining />
  },

  {
    path: "/request/legal/advice",
    element:  <RequestLegalAdvice />
  },

  {
    path: "/request/legal/advice/form",
    element:  <RequestLegalAdviceForm />
  },

  {
    path: "/compliance/reporting/dashboard",
    element:  <ComplianceReportingDashboard />
  },

  {
    path: "/request/training/form",
    element: <RequestOnlineTraining />
  },

  // {
  //   path: "/request/onsite/training",
  //   element: <RequestOnsiteTraining />
  // },

  {
    path: "/infosec",
    element:  <InformationSecurity /> 
  },



  // New Business Flow end

  {
    path: "*",
    element: <Navigate to="/" />,
  },
];


