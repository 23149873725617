import React, { useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import "../../styles/newbusiness.css";
import { Form } from "react-bootstrap";
import { getServerURL } from "../../helper/envConfig";
import api from "../../helper/api";
import { toast } from "react-toastify";

const RequestLegalAdviceForm = () => {
    const serverURL = getServerURL();
    const [formData, setFormData] = useState({
        email: "",
        full_name: "",
        case_details: "",
    });

    const onFormDataChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.full_name) {
            toast.error("Full name is required!");
            return;
        }
        if (!formData.email) {
            toast.error("Email is required!");
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(formData.email)) {
            toast.error("Please enter a valid email address!");
            return;
        }
        if (!formData.case_details) {
            toast.error("Case details is required!");
            return;
        }
        try {
            const res = await api.post(
                serverURL + `users/legal-advice/`,
                formData
            );
            if (res.data.success) {
                toast.success(res.data.message);
                setFormData({
                    email: "",
                    full_name: "",
                    case_details: "",
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Header />
            <main className="business_public_spaces">
                <section className="business_case_study position-relative">
                    <div className="container">
                        <div className="row w-100 w-sm-100 vh-lg-100">
                            <div className="col-12 col-md-6 d-flex justify-content-start align-items-center order-2 order-md-1">
                                <div className="right_side_information request-training">
                                    <h6>
                                        Submit your background information via
                                        the form below.
                                    </h6>
                                    <p className="pb-0 text-bl">
                                        You will receive an{" "}
                                        <strong>
                                            {" "}
                                            initial high-level, non-legal
                                            assessment within 3-4 days.
                                        </strong>{" "}
                                        Further consultation beyond the initial
                                        assessment is billed at{" "}
                                        <strong> €200 per hour.</strong>
                                    </p>
                                    <div className="mt-4 mt-md-4 mt-lg-5">
                                        <Form>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlInput1"
                                            >
                                                <Form.Label>
                                                    Full Name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter your full name"
                                                    name="full_name"
                                                    value={formData.full_name}
                                                    onChange={onFormDataChange}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlInput1"
                                            >
                                                <Form.Label>
                                                    Email Address
                                                </Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter your email address"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={onFormDataChange}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>
                                                    Case Details
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    name="case_details"
                                                    value={
                                                        formData.case_details
                                                    }
                                                    onChange={onFormDataChange}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                        <button
                                            className="w-auto"
                                            onClick={handleSubmit}
                                        >
                                            Submit Request
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 left_side_images order-1 order-md-2 position-sm-absolute end-0 h-100">
                                <img
                                    src="/new-business/request-legal-advice.png"
                                    className="m-auto d-flex request-training-img"
                                    width={500}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default RequestLegalAdviceForm;
