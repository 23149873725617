import React, { useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import "../../styles/newbusiness.css";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { CourseContext } from "../../context/CourseContext";
import { toast } from "react-toastify";

const BusinessUnansweredQuestions = () => {
    const navigate = useNavigate();
    const {pointsData,  getTrailsTotalPoint } = useContext(CourseContext);
    const serverURL = getServerURL();
    const location = useLocation();
    const [question, setQuestion] = useState("");
    const [answers, setAnswers] = useState("");

    const handleQuestionChange = (e) => {
        setQuestion(e.target.value);
    };

    const getAnswer = async () => {
        try {
            const response = await api.postWithToken(
                `${serverURL}trails/ask-openai/`,
                {
                    question: question,
                }
            );
            if (response.data.success) {
                setAnswers(response.data.data.answer);
            } else {
                toast.error("Please try again later.")
            }
        } catch (error) {
            // console.error(error);
            toast.error("Please try again later.")
        }
    };

    const onClearData = () => {
        setQuestion("");
        setAnswers("");
    };

    useEffect(() => {
        if(!pointsData?.total_point_earned) {
            getTrailsTotalPoint()
        }
    }, []);

    return (
        <>
            <Header />
            <main className="business_unanswered_questions">
                <section>
                    <PointsHeader to="/trail/points" />
                </section>
                <section className="questions_boxs">
                    <div className="container">
                        <h3>What would you have liked to know?</h3>
                        <p>
                            Submit your own question, and our AI will provide an
                            answer tailored to your query.
                        </p>

                        <div className="row g-3 justify-content-between">
                            <div className="col-12 col-md-6 pe-lg-5">
                                <label htmlFor="">Write Your Comment</label>
                                <textarea
                                    className="form-control pt-3 bg-secondary fw-400"
                                    rows={6}
                                    placeholder="Type your question here..."
                                    id="floatingTextarea2"
                                    defaultValue={""}
                                    value={question}
                                    onChange={handleQuestionChange}
                                />
                                <div className="d-flex justify-content-end gap-3 mt-4 questions_boxs_btn_1">
                                    <button
                                        className="btn bg-secondary"
                                        onClick={() => navigate(-1)}
                                    >
                                        Back
                                    </button>
                                    <button
                                        className="btn bg-primary text-white"
                                        onClick={getAnswer}
                                    >
                                        Ask AI
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 pe-lg-5 questions_boxs_btn_2 mt-5 mt-md-0">
                                <label htmlFor="">Your Answer</label>
                                <textarea
                                    className="form-control pt-3 bg-transparent fw-400"
                                    rows={6}
                                    placeholder="Your answer will appear here..."
                                    id="floatingTextarea2"
                                    defaultValue={""}
                                    value={answers || ""}
                                />
                                {answers && (
                                    <div className="d-flex justify-content-between align-items-center gap-3 mt-4">
                                        <label htmlFor="" className="mb-0">
                                            Was this helpful?
                                        </label>
                                        <div className="d-flex justify-content-between align-items-center gap-3">
                                            <button
                                                className="btn bg-primary text-white"
                                                onClick={onClearData}
                                            >
                                                Yes
                                            </button>
                                            <button
                                                className="btn bg-secondary"
                                                onClick={onClearData}
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default BusinessUnansweredQuestions;
