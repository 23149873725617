import React, { useState } from 'react';
import { Nav, Tab, Row, Col, Pagination, Form, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import AdminHeader from '../layout/AdminHeader';
import BusinessSidebar from '../components/BusinessSidebar';
import Footer from '../layout/Footer';
import { Link } from 'react-router-dom';
import ComplianceScoreChart from '../components/ComplianceScoreChart';
import DoughnutChart2 from '../components/DoughnutChart2';
import { useEffect } from 'react';
import api from '../helper/api';
import { getImageBaseUrl, getServerURL } from '../helper/envConfig';
import DynamicPagination from "../components/DynamicPagination"
import { encryptAndStore } from '../helper/sessionStore';
import EmployeeTraining from '../components/NewBusiness/EmployeeTraining';
import GaugeChart from '../components/NewBusiness/GaugeChart';
import { Bar, Pie } from 'react-chartjs-2';
import ReactApexChart from 'react-apexcharts';

function BusinessCompliance() {
    const [activeKey, setActiveKey] = useState('1');
    const [data, setData] = useState({
        total_compliance_score_points: {
            top_management:0,
            middle_management:0,
            workforce:0
        }
    });
    const [dataEployee, setDataEployee] = useState([]);
    const serverURL = getServerURL();
    const [goToPageNumber, setGoToPageNumber] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    // State for tracking window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isMobile = windowWidth <= 768; // Or whatever your mobile breakpoint is


    const options = {
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
            tooltip: {
                enabled: true, // Make sure tooltips are enabled
                backgroundColor: '#f9f9f9', // Change the tooltip background color
                titleColor: '#000', // Optional: Change title color
                bodyColor: '#000', // Optional: Change body text color
                borderColor: '#f5f5f5', // Optional: Change border color
                borderWidth: 1, // Optional: Change border width
            },
            // This will remove the labels from the chart
            datalabels: {
                display: false
            },
        },
        cutout: '70%', // This creates the donut shape, adjust as needed
    };

    const [state, setState] = useState({
        columnChartOptions: {
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val + " Points";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#737373"]
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    borderRadius: 8, // Added border radius for bars
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            grid: {
                show: true,
                borderColor: '#edebeb',
                strokeDashArray: 8,
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                }
            },
            xaxis: {
                categories: ['Top Management', 'Middle Management', 'Workforce'],
                axisBorder: {
                    show: true
                },
                labels: {
                    style: {
                        colors: '#333333',
                        fontSize: '12px',
                        fontWeight: 500,
                    },
                },
                axisTicks: {
                    show: false
                },
            },
            yaxis: {
                axisBorder: {
                    show: true
                },
                labels: {
                    formatter: function (value) {
                        return `${value}`;
                    },
                    style: {
                        colors: ['#A3A3A3'],
                        fontSize: '11.88px',
                        fontWeight: 500,
                    },
                },
                min: 0,
                // max: ,
                tickAmount: 5,
                tickValues: [0, 20, 30, 40, 60, 80, 100], // Custom tick values
            },
            colors: ["#B8CCFF", "#FDC93A", "#FF5A59"], // Default colors, but this will be overridden by the series
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                fontSize: '12px',
                fontWeight: 500,
                fontFamily: 'Poppins, sans-serif',
                labels: {
                    colors: '#A3A3A3',
                },
                markers: {
                    size: 6,
                    shape: 'round',
                },
            },
        },
        columnChartSeries: [{
            // name: '2023',
            data: [
                { x: 'Top Management', y: data?.total_compliance_score_points?.top_management || 0, fillColor: "#B8CCFF" }, // Custom color for the first bar
                { x: 'Middle Management', y: data?.total_compliance_score_points?.middle_management, fillColor: "#FDC93A" }, // Custom color for the second bar
                { x: 'Workforce', y: data?.total_compliance_score_points?.workforce, fillColor: "#FF5A59" } // Custom color for the third bar
            ],
            tooltip: {
                enabled: false,
              }
        }],
    })




    const maxPerformance = data?.regulatory_compliance_and_risk_management?.reduce((max, current) => (current.percentage > max.percentage ? current : max), data?.regulatory_compliance_and_risk_management[0])
    const minPerformance = data?.regulatory_compliance_and_risk_management?.reduce((min, current) => (current.percentage < min.percentage ? current : min),  data?.regulatory_compliance_and_risk_management[0])
    // Update windowWidth when the window is resized
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSelect = (key) => {
        setActiveKey(key);
    };

    const getData = async () => {

        try {
            const response = await api.getWithToken(`${serverURL}users/business-owner/overall-score/`);
            setData(response.data.data)
            setState({
                ...state,
                columnChartSeries: [{
                    // name: '2023',
                    data: [
                        { x: 'Top Management', y: response.data.data?.total_compliance_score_points?.top_management || 0, fillColor: "#B8CCFF" }, // Custom color for the first bar
                        { x: 'Middle Management', y: response.data.data?.total_compliance_score_points?.middle_management, fillColor: "#FDC93A" }, // Custom color for the second bar
                        { x: 'Workforce', y: response.data.data?.total_compliance_score_points?.workforce, fillColor: "#FF5A59" } // Custom color for the third bar
                    ],
                }],
            })
        } catch (error) {
            console.error(error);

        };

    }

    const getDataEployee = async () => {
        try {
            const response = await api.getWithToken(`${serverURL}users/business-owner/compliance-employee/?page=${currentPage}&page_size=${rowsPerPage}`);
            setDataEployee(response.data)
            setTotalPages(response.data.total_pages)
        } catch (error) {
            console.error(error);
        };

    }

    // Effect for fetching quizzes
    useEffect(() => {
        getData();
        getDataEployee();
    }, [activeKey, currentPage, rowsPerPage]);


    const handleSelectChange = (e) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page after changing row size
    };

    const handleGoToPageChange = (e) => {
        setGoToPageNumber(e.target.value);
    };

    const handleGoToPage = (e) => {
        e.preventDefault();

        const pageNumber = parseInt(goToPageNumber, 10);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }

    };

    // State to track the dark mode status
    const [isDarkMode, setIsDarkMode] = useState(false);

    // Function to handle the toggle switch
    const handleToggle = () => {
        setIsDarkMode(!isDarkMode);
    };


    const datachart = {
        labels: ['Top Management', 'Middle Management', 'Workforce'],
        datasets: [
            {
                // label: 'My First Dataset',
                data: [data?.total_compliance_score?.top_management, data?.total_compliance_score?.middle_management, data?.total_compliance_score?.workforce],
                backgroundColor: [
                    '#B8CCFF',
                    '#FDC93A',
                    '#FF5A59',
                ],
                borderColor: '#fff',  // Optional: Add border color if needed
                borderWidth: 10,  // Optional: Add border width if needed
                borderRadius: 10, // Set border radius here
                hoverOffset: 4,
            },
        ],
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const labels = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    const chartData = labels.map(month => data?.completed_courses_month_wise?.[month] || 0);
    const bar_data = {
        labels: labels,
        datasets: [
            {
                label: 'My First Dataset',
                data: chartData,
                backgroundColor: Array(20).fill('#002FA7'),
                borderRadius: 100,
                barThickness: 20,
                borderSkipped: false,
                barPercentage: 0.25,
                categoryPercentage: 0.5,
            },
        ],
    };

    const bar_config = {
        type: 'bar',
        data: bar_data,
        options: {
            scales: {
                y: {
                    beginAtZero: true,
                    stepSize: 10,
                    border: {
                        dash: [10, 10],
                        color: '#ffff',
                    },
                    grid: {
                        color: '#B8CCFF',
                    },
                    ticks: {
                    
                    },
                },
                x: {
                    ticks: {
                        display: true,
                    },
                    grid: {
                        display: false,
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
        },
    };

    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <BusinessSidebar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className="d-md-flex justify-content-between align-items-center gap-3 pb-0 pb-md-4">
                                            <div className='bulk-upload-details'>
                                                <h2 className="text-black fw-600">Compliance Score Dashboard</h2>
                                                {/* <h4>Comprehensive Compliance Score Breakdown</h4>
                                                <p>Emphasize the critical role of all organizational levels in compliance, using the Wirecard and Volkswagen scandals as cautionary examples. Highlight the importance of ethical behavior and regulatory adherence.</p> */}
                                            </div>
                                        </div>
                                        {/* <div className='case-studies-card'>
                                            <h6>Case Studies</h6>
                                            <h3>Scandal Overview</h3>
                                            <p>In 2020, the Wirecard scandal shocked the financial world. Learn about the missing funds, company insolvency, and market value loss</p>
                                            <p>The Volkswagen emissions scandal revealed lapses in compliance. Explore how manipulation and management knowledge impacted the company.</p>

                                            <button onClick={handleShow}>Read More</button>
                                        </div>
                                        <div className="row g-4 pt-4">
                                            <div className="col-12 col-lg-6">
                                                <div className="card border-0">
                                                    <div className="card-header bg-transparent border-secondary d-flex align-items-center py-4 position-relative border-0">
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Overall Compliance Score
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-4 compliance-details">
                                                        <div className="overflow-auto mb-4">
                                                            <div className="barchart chart-sm">
                                                                <GaugeChart />
                                                            </div>
                                                        </div>
                                                        <div className='chart-details'>
                                                            <h2>B+</h2>
                                                            <h3>Your Compliance Score is Good</h3>
                                                            <h4>Last Month Score: <span>C</span></h4>
                                                            <h5>Trend: <span>Improving <img src="/new-business/improving.png" width={20} alt="arrow" /></span></h5>
                                                            <button className='d-flex m-auto mt-4' onClick={handleShow}>
                                                                See More Details
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card border-0 mt-4">
                                                    <div className="card-header bg-transparent border-secondary d-flex align-items-center py-4 position-relative border-0 pb-0">
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Regulatory Compliance & Risk Management
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-4 pt-0 regulatory-compliance">
                                                        <div className="progress-main-2 mt-4">
                                                            {
                                                                data?.regulatory_compliance_and_risk_management?.map((item, index) => {
                                                                    return (
                                                                       <>
                                                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                                                            <p className="text-muted fw-400 fs-7 mb-0">
                                                                               {item.category_name}
                                                                            </p>
                                                                            <p className="mb-0 error-line">
                                                                                {item.message}
                                                                            </p>
                                                                        </div>
                                                                        <div className='d-flex align-items-center gap-3 mb-4'>
                                                                            <div
                                                                                className="progress progress-height01 w-100"
                                                                                role="progressbar"
                                                                                aria-label="Basic example"
                                                                                aria-valuenow={0}
                                                                                aria-valuemin={0}
                                                                                aria-valuemax={100}
                                                                            >
                                                                                <div className={`progress-bar progress0${index+1}`}  style={{width: `${item.percentage}%`}} />
                                                                            </div>
                                                                            <span className='progress-number'>{item.percentage}%</span>
                                                                        </div>
                                                                       </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card border-0">
                                                    <div className="card-header bg-transparent border-secondary d-flex align-items-center py-4 position-relative border-0 pb-0">
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Employee Training Completion
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-4 pt-0 regulatory-compliance">
                                                        <EmployeeTraining percentage={data?.employee_training_completion}/>
                                                    </div>
                                                </div>
                                                <div className="card border-0 mt-4">
                                                    <div className="card-header bg-transparent border-secondary d-flex align-items-center py-4 position-relative border-0 pb-0">
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Leaderboard
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-4 pt-0 overflow-auto">
                                                        <table class="table text-center leaderboard-table m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Rank</th>
                                                                    <th className='text-nowrap'>Employee Name</th>
                                                                    <th>Department</th>
                                                                    <th width="100">Compliance Score (%)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='text-center'>
                                                                { data?.leaderboard_list?.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item.username}</td>
                                                                            <td>{item.total_completed_courses}</td>
                                                                            <td>{item.percentage}%</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="card border-0 mt-4">
                                                    <div className="card-header bg-transparent border-secondary d-flex align-items-center py-4 position-relative border-0 pb-0">
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Whistleblowing & Incident Reporting
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-4 pt-0">
                                                        <div className='d-flex align-items-center gap-3 mt-3'>
                                                            <div className={`cont ${isDarkMode ? 'dark-mode' : ''}`}>
                                                                <div className="toggle">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="mode-toggle"
                                                                        className="toggle__input"
                                                                        checked={isDarkMode}
                                                                        onChange={handleToggle}
                                                                    />
                                                                    <label htmlFor="mode-toggle" className="toggle__label"></label>
                                                                </div>
                                                            </div>
                                                            <p className='my-0 fw-400 fs-6 pb-2'>
                                                                Is a whistleblowing channel mandatory?
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id="tooltip-example" className='custom-tooltip'>
                                                                        <ul className='ps-3'>
                                                                            <li><span className='fw-600'>Mandatory for companies with 50+ employees</span></li>
                                                                            <li><span className='fw-600'>Public sector bodies must comply.</span> except small municipalities (10,000 people)</li>
                                                                            <li><span className='fw-600'>High-risk sectors (finance, AML, transport, etc.) must comply regardless of size.</span></li>
                                                                            <li><span className='fw-600'>Channels must allow confidential reporting, acknowledge within 7 days, and respond within 3 months.</span></li>
                                                                            <li><span className='fw-600'>Can be internal or outsourced,</span>  but must ensure security and follow-up.</li>
                                                                        </ul>
                                                                    </Tooltip>}
                                                                >
                                                                    <span className="d-inline-block">
                                                                        <img src='png/questionMark.png' className='ms-2' alt='' style={{ height: "20px", width: "20px" }} />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </p>
                                                        </div>
                                                        <div className='d-flex align-items-center gap-3 mt-3'>
                                                            <div className={`cont ${isDarkMode ? 'dark-mode' : ''}`}>
                                                                <div className="toggle">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="mode-toggle"
                                                                        className="toggle__input"
                                                                        checked={isDarkMode}
                                                                        onChange={handleToggle}
                                                                    />
                                                                    <label htmlFor="mode-toggle" className="toggle__label"></label>
                                                                </div>
                                                            </div>
                                                            <p className='my-0 fw-400 fs-6 pb-2'>Does it exist?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-4 pt-2 pb-4">
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                                <div className="card rounded-4 mt-4 h-100 bg-light">
                                                    <div className="card-body p-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="text-black fw-600 fs-18 mb-4">Total Compliance Score</p>
                                                        </div>
                                                        <div className='position-relative pie-chart'>
                                                            <Pie data={datachart} options={options} />

                                                            <div className='position-absolute top-50 start-50 translate-middle pie-chart-box'>
                                                                <div>
                                                                    <h6>Compliance Score</h6>
                                                                    <p>Visualization</p>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-8">
                                                <div className="card rounded-4 mt-4 h-100">
                                                    <div className="card-body p-3 top-management-details">
                                                        <p className="text-black fw-600 fs-12 mb-3">Top Management</p>
                                                        <ul>
                                                            <li>Achieve a full compliance score by completing offline schooling sessions conducted by Dr. Niels Beisinghoff. These sessions are crucial for top-level executives.</li>

                                                            <li>
                                                                In-person training plays a critical role in compliance awareness for top management. Learn from the failures of Wirecard and Volkswagen, where lack of training led to major scandals.
                                                            </li>

                                                            <li>
                                                                View and schedule your offline training sessions. Pricing varies based on the complexity and duration of the training.
                                                            </li>
                                                        </ul>

                                                        <p className="text-black fw-600 fs-12 mb-3">Middle Management</p>

                                                        <ul>
                                                            <li>Middle management's understanding of compliance is vital. Offline schooling sessions are recommended to reinforce compliance practices.</li>
                                                        </ul>

                                                        <p className="text-black fw-600 fs-12 mb-3">Workforce</p>
                                                        <ul>
                                                            <li>Provide online training options for your workforce. Track progress and ensure compliance awareness among employees.</li>
                                                            <li>Monitor employee progress in completing mandatory training modules. Identify areas for improvement and compliance gaps.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        {/* <div className="row g-4 pt-2 pb-4">
                                            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                                                <div className="card rounded-4 mt-4 h-100">
                                                    <div className="card-body p-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="text-black fw-600 fs-18 mb-4">Current Compliance Score</p>
                                                        </div>
                                                        <div className='position-relative pie-chart'>
                                                            <ReactApexChart options={state.columnChartOptions} series={state.columnChartSeries} type="bar" height={350} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                                                <div className="card rounded-4 mt-4 h-100">
                                                    <div className="card-body p-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="text-black fw-600 fs-18 mb-4">Total Compliance Score</p>
                                                        </div>
                                                        <div className='position-relative pie-chart'>
                                                            <ReactApexChart options={state.columnChartOptions} series={state.columnChartSeries} type="bar" height={350} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="row g-4 mt-2 mt-md-0">
                                            <div className="col-12 col-lg-12">
                                                <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
                                                    <div className="overflow-auto">
                                                        <Nav className="nav-pills mb-2 group-view-list gap-4 col-12 col-md-7 col-lg-5 flex-nowrap" id="pills-tab" role="tablist">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="1" className="fw-600 fs-6 text-black">Overall Score</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="2" className="fw-600 fs-6 text-black">Employees</Nav.Link>
                                                            </Nav.Item>
                                                            {/* <Nav.Item>
                                                                                                        <Nav.Link eventKey="3" className="fw-400 fs-6 text-black">Billing</Nav.Link>
                                                                                                    </Nav.Item> */}
                                                        </Nav>
                                                    </div>
                                                    <Tab.Content className='mb-5 pb-0 pb-md-4'>


                                                        <Tab.Pane eventKey="1">
                                                            <Row className='mt-5 g-4'>
                                                                <Col className="col-12 col-md-6 col-lg-4">
                                                                    <div className="card border-0 rounded-3 h-100">
                                                                        <div className="card-body p-4">
                                                                            <h4 className='fs-20 fw-600 text-light-1 text-capitalize'>Total Employees</h4>
                                                                            <p className='display-6 fw-600 mb-2'>{data?.total_employees}</p>
                                                                            <p className='text-light-2 fw-400'>Employee</p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-12 col-md-6 col-lg-4">
                                                                    <div className="card border-0 rounded-3 h-100">
                                                                        <div className="card-body p-4">
                                                                            <h4 className='fs-20 fw-600 text-light-1 text-capitalize'>Total Courses Mandatory</h4>
                                                                            <p className='display-6 fw-600 mb-2'>{data?.total_mandatory_courses}</p>
                                                                            <p className='text-light-2 fw-400'>Courses</p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-12 col-md-6 col-lg-4">
                                                                    <div className="card border-0 rounded-3 h-100">
                                                                        <div className="card-body p-4">
                                                                            <h4 className='fs-20 fw-600 text-light-1 text-capitalize'>Total mandatory courses completed</h4>
                                                                            <p className='display-6 fw-600 mb-2'>{data?.total_completed_courses}</p>
                                                                            <p className='text-light-2 fw-400'>Courses</p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='g-4 mt-2'>
                                                                <Col className="col-12 col-md-12 col-lg-6">
                                                                    <div className="card border-0 rounded-3 h-100">
                                                                        <div className="card-body p-4">
                                                                            <p className='text-light-1 fs-20 fw-600'>Percentage of Completed Courses</p>
                                                                            <h3 className='fw-600'>{data?.completed_percentage} %</h3>
                                                                            <div className='mb-4'>
                                                                                {/* <img src="admin-images/png/arrow-up.png" alt="" /> */}
                                                                                {/* <span className='text-light-4 fw-600 ps-2'>2.1%</span>
                                                                                                                        <span className='text-light-2 fw-400'> vs last month</span> */}
                                                                            </div>
                                                                            <ComplianceScoreChart data={data} />
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col className="col-12 col-md-12 col-lg-6">
                                                                    <div className="card border-0 rounded-3 h-100">
                                                                        <div className="card-body p-4">
                                                                            <p className='text-light-1 fs-20 fw-600'>Overall Compliance Score</p>
                                                                            {/* <h3 className='fw-600'> {data?.completed_percentage}/100</h3> */}
                                                                            <div className='mb-4'>
                                                                                {/* <img src="admin-images/png/arrow-down.png" alt="" /> */}
                                                                                {/* <span className='text-light-3 fw-600 ps-2'>3.5%</span>
                                                                                                                        <span className='text-light-2 fw-400'> vs last month</span> */}
                                                                            </div>
                                                                            <DoughnutChart2 data={data} />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                {/* <Col className="col-12 col-md-12 col-lg-6">
                                                                                                            <div className="card border-0 rounded-3 h-100">
                                                                                                                <div className="card-body p-4">
                                                                                                                    <p className='text-light-1 fs-20 fw-600'>Overall Compliance Score</p>
                                                                                                                    <h3 className='fw-600'>79/100</h3>
                                                                                                                    <div className='mb-4'>
                                                                                                                        <img src="admin-images/png/arrow-down.png" alt="" />
                                                                                                                        <span className='text-light-3 fw-600 ps-2'>3.5%</span>
                                                                                                                        <span className='text-light-2 fw-400'> vs last month</span>
                                                                                                                    </div>
                                                                                                                    <ComplianceScoreChart />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Col> */}
                                                                {/* <Col className="col-12 col-md-12 col-lg-6">
                                                                                                            <div className="card border-0 rounded-3 h-100">
                                                                                                                <div className="card-body p-4">
                                                                                                                    <p className='text-light-1 fs-20 fw-600'>Total Points</p>
                                                                                                                    <h5 className='display-3 fw-600 text-center mt-5 naturenova'>79</h5>
                                                                                                                    <p className='text-center fs-20 fw-600 mt-3'>Compliance Score</p>
                                                                                                                    <div className='compliance-score-progress d-flex mt-5'>
                                                                                                                        <p className='very-low' style={{ width: '20%' }}></p>
                                                                                                                        <p className='low' style={{ width: '20%' }}></p>
                                                                                                                        <p className='medium' style={{ width: '20%' }}></p>
                                                                                                                        <p className='high' style={{ width: '20%' }}></p>
                                                                                                                        <p className='very-high' style={{ width: '20%' }}></p>
                                                                                                                    </div>
                                                                                                                    <ul className="px-0 point-list mt-5 d-flex flex-wrap align-items-center gap-4">
                                                                                                                        <li><span className='very-low'></span><b>1-20</b> Very low</li>
                                                                                                                        <li><span className='low'></span><b>21-40</b> Low</li>
                                                                                                                        <li><span className='medium'></span><b>41-60</b> Medium</li>
                                                                                                                        <li><span className='high'></span><b>61-80</b> High</li>
                                                                                                                        <li><span className='very-high'></span><b>81-100</b> Very high</li>
                                                                                                                    </ul>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Col> */}
                                                            </Row>
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="2">
                                                            <div className="card border-0 mt-3 mt-md-5">
                                                                <div className="card-body">
                                                                    <div className="table-responsive compliance-score-dashboard">
                                                                        <table className="table align-middle">
                                                                            <thead>
                                                                                <tr className="pb-5">
                                                                                    <th className="table-th-width-350">
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <p className="fw-600 fs-6 text-bl mb-0 text-nowrap">Name</p>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th>
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <p className="fw-600 fs-6 text-bl mb-0 text-nowrap">
                                                                                                Total Points
                                                                                            </p>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th>
                                                                                        <p className="fw-600 fs-6 text-bl mb-0 text-nowrap">
                                                                                            Total Courses Done
                                                                                        </p>
                                                                                    </th>
                                                                                    <th>
                                                                                        <p className="fw-600 fs-6 text-bl mb-0 text-nowrap">
                                                                                            Compliance Score
                                                                                        </p>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {dataEployee?.results?.employees.map((data, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="rounded-start-4">
                                                                                            <div className="row">
                                                                                                <div className="col-12 overflow-hidden">
                                                                                                    <Link to="/employee-profile" onClick={() => encryptAndStore('employee_id', data.employee_id)} className="d-flex align-items-center gap-2">
                                                                                                        <img
                                                                                                            src={data.image ? `${getImageBaseUrl()}${data.image}` : "admin-images/png/toppers1.png"}
                                                                                                            alt=""
                                                                                                            className="toppers"
                                                                                                        />
                                                                                                        <div className="student-details">
                                                                                                            <p className="fw-400 fs-7 text-bl mb-1 table-th-width-167">
                                                                                                                {data.username}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="d-flex align-items-center gap-1">
                                                                                                <p className="loder-text mb-0">{data.total_points}</p>
                                                                                                <img src="admin-images/png/trophy.png" className="" alt="" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="d-flex align-items-center gap-1">
                                                                                                <p className="loder-text mb-0">{data.total_completed_courses}</p>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="rounded-end-4">
                                                                                            <p className="fw-400 fs-6 text-bl mb-0 text-nowrap">
                                                                                                {data.compliance_score ? data.compliance_score : 0}
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>

                                                    </Tab.Content>
                                                    <div className="col-12 d-flex justify-content-center mt-4">
                                                        {activeKey == 2 &&
                                                            <Pagination className='table-pagination align-items-center'>
                                                                {!isMobile && (
                                                                    <li className="page-item">
                                                                        <a className="page-link w-auto h-auto" role="button" tabIndex="0">
                                                                            Rows per page
                                                                            <Form.Select aria-label="Rows per page" className='select-number' value={rowsPerPage} onChange={handleSelectChange}>
                                                                                <option value="10">10</option>
                                                                                <option value="15">15</option>
                                                                                <option value="20">20</option>
                                                                                <option value="25">25</option>
                                                                            </Form.Select>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {
                                                                    totalPages >= 2 &&
                                                                    <>
                                                                        <DynamicPagination
                                                                            currentPage={currentPage}
                                                                            totalPages={totalPages}
                                                                            onPageChange={(newPage) => {
                                                                                setCurrentPage(newPage);
                                                                            }}
                                                                        />
                                                                        <div className='ms-0 ms-md-5 d-flex'>
                                                                            <a class="page-link w-auto h-auto border-0 bg-transparent d-none d-lg-block" role="button" tabindex="0" >
                                                                                <span aria-hidden="true" className='text-muted fw-400 fs-7 text-nowrap'>Go to page</span>
                                                                            </a>
                                                                            <input className='form-control border-0 border-bottom rounded-0 w-50 d-none d-lg-block'
                                                                                type="number"
                                                                                min="1" // Minimum value as 1 since page numbers start from 1
                                                                                max={totalPages} // Maximum value as the total number of pages
                                                                                onChange={handleGoToPageChange} value={goToPageNumber}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'Enter') {
                                                                                        handleGoToPage(e);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <div className='ms-3 cursor-pointer d-none d-lg-block'>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => handleGoToPage(e)}>
                                                                                    <path d="M10.0037 6L8.59375 7.41L13.1737 12L8.59375 16.59L10.0037 18L16.0037 12L10.0037 6Z" fill="#242731" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>

                                                                    </>
                                                                }

                                                            </Pagination>

                                                        }

                                                    </div>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />

            <Modal centered show={show} size='lg' className='compliance-performance-modal' onHide={handleClose}>
                <Modal.Body>
                    <h4>Compliance Performance Breakdown</h4>
                    <p>Your company's compliance score over time with trends and insights.</p>
                    <div className="overflow-auto mb-5">
                        <div className="barchart-progress-team">
                            <Bar height={100} data={bar_data} options={bar_config.options} />
                        </div>
                    </div>
                    <h5>Highest Performing Area: <span>{maxPerformance?.category_name} ({maxPerformance?.percentage}%)</span></h5>
                    <h5>Critical Weakness: <span> {minPerformance?.category_name} ({minPerformance?.percentage}%)</span></h5>

                    <div className='d-flex justify-content-end'>
                        <button className='close-btn' onClick={handleClose}>Close</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BusinessCompliance
