import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { Fragment, useContext, useEffect } from 'react';
import route from "./helper/route"
import Loader from './components/Loader';
import { CourseContext } from './context/CourseContext';
import { ScrollToTop } from './components/ScrollToTop';
import ChildComponent from './components/ChildComponent';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './assets/css/custom-toast.css'
import ProtectedRouteTemp from './helper/ProtectedRouteTemp';
import { decryptFromStoreLoc } from './helper/localStore';

function App() {

  const { mainLoder } = useContext(CourseContext);
  const decryptedInfoResult = decryptFromStoreLoc('user');
  

  return (
    <BrowserRouter>
      <Fragment>
        {mainLoder &&
          <Loader ismain="main" />
        }
        <ChildComponent /> {/* Include the ChildComponent */}
        <ScrollToTop>
          <Routes>
            {route?.map((route, index) => (
              <Route key={index} exact path={route.path} element={
                <ProtectedRouteTemp>
                {route.element}
                </ProtectedRouteTemp>
                } />
            ))}
          </Routes>
        </ScrollToTop>
      </Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />    
      </BrowserRouter>
  );
}

export default App;
