import React from 'react';
import ReactApexChart from 'react-apexcharts';

const EmployeeTraining = ({ percentage =  0 }) => {
    const maleSeries = [percentage];
    const maleOptions = {
        chart: {
            type: 'radialBar',
            height: '100%', // Responsive height
            width: '100%',  // Responsive width
        },
        legend: {
            show: false,
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: '50%',
                },
                track: {
                    background: '#DFEEDB',
                },
                dataLabels: {
                    show: true,
                    name: {
                        show: false,
                    },
                    value: {
                        show: true,
                        formatter: function (val) {
                            return parseInt(val) + '%';
                        },
                        color: '#D2E23D',
                        fontSize: '50px', // Default font size
                        fontWeight: '700',
                    }
                }
            },
        },
        labels: ['Employee Training Completion'],
        colors: ['#D2E23D'],
        stroke: {
            lineCap: 'round'
        },
        responsive: [
            {
                breakpoint: 1200, // Large screens
                options: {
                    chart: {
                        height: '400px', // Adjust height for larger screens
                    },
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                value: {
                                    fontSize: '60px', // Adjust font size for large screens
                                }
                            }
                        }
                    }
                }
            },
            {
                breakpoint: 768, // Tablet screens
                options: {
                    chart: {
                        height: '350px', // Adjust height for tablets
                    },
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                value: {
                                    fontSize: '45px', // Adjust font size for tablets
                                }
                            }
                        }
                    }
                }
            },
            {
                breakpoint: 480, // Mobile screens
                options: {
                    chart: {
                        height: '300px', // Adjust height for mobile
                    },
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                value: {
                                    fontSize: '30px', // Adjust font size for mobile
                                }
                            }
                        }
                    }
                }
            }
        ]
    }


    return (
        <div className="card-wrapper">
            <div className="card-body">
                <ReactApexChart
                    options={maleOptions}
                    series={maleSeries}
                    type="radialBar"
                />
            </div>
        </div>
    );
};

export default EmployeeTraining;
