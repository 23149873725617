import React from 'react'
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import "../../styles/newbusiness.css"
import { Link } from 'react-router-dom';

const RequestLegalAdvice = () => {


    return (
        <>
            <Header />
            <main className='business_public_spaces'>
                <section className='business_case_study position-relative'>
                    <div className="container">
                        <div className="row w-100 w-sm-100 vh-lg-100">
                            <div className="col-12 col-md-6 d-flex justify-content-start align-items-center order-2 order-md-1">
                                <div className='right_side_information request-training'>
                                    <h2>Request Legal Advice from a Fully Qualified German Lawyer</h2>
                                    <p className='pb-0'>Get expert legal guidance on compliance, GDPR, AI law, IT law, and company law within German and EU regulations.</p>
                                    <div className='d-flex align-items-center gap-3'>
                                        <Link to="/request/legal/advice/form" className='d-flex justify-content-between align-items-center gap-3 w-auto'>
                                            Get Legal Help Now
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 6.99854H21L16 1"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M21 6.99805L16 12.9995"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 left_side_images order-1 order-md-2 position-sm-absolute end-0 h-100">
                                <img src="/new-business/request-legal-advice.png" className='m-auto d-flex request-training-img' width={500} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default RequestLegalAdvice
