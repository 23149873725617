import React from 'react'
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import "../../styles/newbusiness.css"
import { Link } from 'react-router-dom';

const RequestTraining = () => {


    return (
        <>
            <Header />
            <main className='business_public_spaces'>
                <section className='business_case_study position-relative'>
                    <div className="container">
                        <div className="row w-100 w-sm-100 vh-lg-100">
                            <div className="col-12 col-md-6 d-flex justify-content-start align-items-center order-2 order-md-1">
                                <div className='right_side_information request-training'>
                                    <h2>Request an Online or Onsite Training</h2>
                                    <p className='pb-0'>Looking for personalized training? We offer one-on-one training sessions on any topic you need! Choose between an online training session or an onsite session at your location.</p>
                                    <ul className='list-unstyled ps-4'>
                                        <li className='pb-2'><strong>Online Training:</strong> Available in 1-hour slots at a rate of <strong>€200 per hour.</strong></li>
                                        <li className='pb-2'><strong>Online Training:</strong> Costs vary depending on the <strong>length of the training, travel costs from Munich, Germany, and audience size.</strong></li>
                                    </ul>
                                    <div className='d-flex align-items-center gap-3'>
                                        <Link to="/request/training/form" className='d-flex justify-content-between align-items-center gap-3 w-auto'>
                                        Request Training Now
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 6.99854H21L16 1"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M21 6.99805L16 12.9995"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 left_side_images order-1 order-md-2 position-sm-absolute end-0 h-100">
                                <img src="/new-business/request-training.png" className='m-auto d-flex request-training-img' width={500} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default RequestTraining
