import React, { useContext, useEffect, useState } from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../helper/api';
import { getServerURL } from '../../helper/envConfig';
import { CourseContext } from '../../context/CourseContext';
import PointsHeader from '../../components/NewBusiness/PointsHeader';

function BusinessFacts() {
    const { user ,  getTrailsTotalPoint } = useContext(CourseContext);
    const serverURL = getServerURL()
    const location = useLocation()
    const navigate = useNavigate()
    const [facts, setFacts] = useState([])

    const getFacts = async () => {
            try {
                const type = ( user.main_role === 1 || user.role === 2) ? "business" : "kid"
                const response = await api.getWithToken(
                    `${serverURL}trails/facts-to-know/?type=${type}&trail_id=${location?.state?.trailId}`
                );
                setFacts(response.data.data);
            } catch (error) {
                console.log(error);
            }
    };

    useEffect(() => {
        getTrailsTotalPoint()
        getFacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const splitItems = [
        facts.slice(0, 5), // First 5 items
        facts.slice(5),    // Last 5 items
    ];

    const handleNavigate = () => {
        navigate("/trail/fact/details", {
            state: {
                facts: facts,
                trailId: location?.state?.trailId,
                name: location?.state?.name,
            },
        });
    }

    return (
        <>
            <Header />
            <PointsHeader to="/trail/points" />
            <section className='py-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="card border-0 shadow-none rounded-0 facts_card_title mb-5">
                                <div className="card-body">
                                    <h3>Top 10 Things to Know</h3>
                                    <p>Key highlights you must remember from this course</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row facts_card_list px-4">
                        {splitItems.map((group, colIndex) => (
                            <div key={colIndex} className="col-6">
                                {group.map((item, index) => (
                                    <div key={index} className="d-flex align-items-center gap-4 mb-3">
                                        <h4>{index + (colIndex === 0 ? 1 : 6) }</h4>
                                        <p>{item.title}</p>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                    <div className="d-flex justify-content-end pt-4 pb-5">
                        <button
                            className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 mt-4"
                             onClick={handleNavigate}
                        >
                            Proceed
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={22}
                                height={14}
                                viewBox="0 0 22 14"
                                fill="none"
                                className="ms-5"
                            >
                                <path
                                    d="M1 6.99854H21L16 1"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M21 6.99805L16 12.9995"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default BusinessFacts