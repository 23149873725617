import React, { useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import "../../styles/newbusiness.css";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { decryptFromStore } from "../../helper/sessionStore";
import { CourseContext } from "../../context/CourseContext";

const BusinessQuizResults = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [result, setResult] = useState({});
    const [selectedUser, setSelectedUser] = useState();
    const { getTrailsTotalPoint } = useContext(CourseContext);

    useEffect(() => {
        const resultFromStore = decryptFromStore("gamifiedResult");
        const selectedUserFromStore = decryptFromStore("selectedUser");
        if (resultFromStore && selectedUserFromStore) {
            setResult(resultFromStore);
            setSelectedUser(selectedUserFromStore.user);
        }
    }, []);

    const handleNavigate = () => {
        navigate("/trail/gamified-quiz", {
            state: {
                step: true,
                trailId: location?.state?.trailId,
                name: location?.state?.name,
            },
        });
    };

    function getOpponent() {
        if (selectedUser === "Ada") {
            return "Ommo";
        }
        if (selectedUser === "Ommo") {
            return "Ada";
        }
    }

    useEffect(() => {
        getTrailsTotalPoint();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            <main className="business_quiz_results">
                <section>
                    <PointsHeader to="/trail/points" />
                </section>
                <section>
                    <div className="container business_puzzle">
                        <div className="puzzle_top_box">
                            <div className="row pb-md-4">
                                <div className="col-12 col-lg-5 col-xl-8 order-2 order-lg-1">
                                    <span>Results</span>
                                    <h2>
                                        {result.isTie
                                            ? "Tie!"
                                            : result?.winner === selectedUser
                                            ? "You  Won!"
                                            : getOpponent() + " Won!"}
                                    </h2>
                                    <p>
                                        Congratulations! You have successfully
                                        completed the quiz
                                    </p>
                                </div>
                                <div className="col-12 col-lg-7 col-xl-4 business_quiz_main gamified_quiz_results mb-4 order-1 order-lg-2">
                                    <div className="d-flex justify-content-center justify-content-lg-start align-items-center gap-4 w-100">
                                        <div className="text-center quiz_user">
                                            <h6>You</h6>
                                            {!result.isTie ? (
                                                result?.winner ===
                                                selectedUser ? (
                                                    <div className="quiz_user_image active position-relative">
                                                        <div className="choose_quiz_user_image_circle d-flex justify-content-center align-items-center m-auto">
                                                            {selectedUser ===
                                                            "Ada" ? (
                                                                <img
                                                                    src="/new-business/ada.webp"
                                                                    className="position-relative z-3 pt-3"
                                                                    width={80}
                                                                    alt="Ada"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src="/new-business/ommo.webp"
                                                                    className="position-relative z-3 pt-3"
                                                                    width={80}
                                                                    alt="Ommo"
                                                                />
                                                            )}
                                                        </div>
                                                        <img
                                                            src="/new-business/won_ring.webp"
                                                            className="won_ring"
                                                            alt="Ada"
                                                        />
                                                        <div className="d-flex justify-content-center">
                                                            <button className="border-0 ada_btn">
                                                                {selectedUser}{" "}
                                                                (You)
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="quiz_user_image position-relative">
                                                        <div className="choose_quiz_user_image_circle d-flex justify-content-center align-items-center m-auto">
                                                            {selectedUser ===
                                                            "Ada" ? (
                                                                <img
                                                                    src="/new-business/ada.webp"
                                                                    className="position-relative z-3 pt-3"
                                                                    width={80}
                                                                    alt="Ada"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src="/new-business/ommo.webp"
                                                                    className="position-relative z-3 pt-3"
                                                                    width={80}
                                                                    alt="Ommo"
                                                                />
                                                            )}
                                                        </div>
                                                        <img
                                                            src="/new-business/won_ring.webp"
                                                            className="won_ring d-none"
                                                            alt="Ada"
                                                        />
                                                        <div className="d-flex justify-content-center">
                                                            <button className="border-0 ada_btn">
                                                                {selectedUser}{" "}
                                                                (You)
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <div className="quiz_user_image position-relative">
                                                    <div className="choose_quiz_user_image_circle d-flex justify-content-center align-items-center m-auto">
                                                        {selectedUser ===
                                                        "Ada" ? (
                                                            <img
                                                                src="/new-business/ada.webp"
                                                                className="position-relative z-3 pt-3"
                                                                width={80}
                                                                alt="Ada"
                                                            />
                                                        ) : (
                                                            <img
                                                                src="/new-business/ommo.webp"
                                                                className="position-relative z-3 pt-3"
                                                                width={80}
                                                                alt="Ommo"
                                                            />
                                                        )}
                                                    </div>
                                                    <img
                                                        src="/new-business/won_ring.webp"
                                                        className="won_ring d-none"
                                                        alt="Ada"
                                                    />
                                                    <div className="d-flex justify-content-center">
                                                        <button className="border-0 ada_btn">
                                                            {selectedUser} (You)
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            <p>{result?.userScore} points</p>
                                        </div>
                                        <div>
                                            <h3 className="fs-23 fw-500 text-center text-white">
                                                VS{" "}
                                            </h3>
                                        </div>
                                        <div className="text-center quiz_user">
                                            <h6>Your Opponent</h6>
                                            {!result.isTie ? (
                                                result?.winner !==
                                                selectedUser ? (
                                                    <div className="quiz_user_image active position-relative">
                                                        <div className="choose_quiz_user_image_circle d-flex justify-content-center align-items-center m-auto">
                                                            {selectedUser !==
                                                            "Ada" ? (
                                                                <img
                                                                    src="/new-business/ada.webp"
                                                                    className="position-relative z-3 pt-3"
                                                                    width={80}
                                                                    alt="Ada"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src="/new-business/ommo.webp"
                                                                    className="position-relative z-3 pt-3"
                                                                    width={80}
                                                                    alt="Ommo"
                                                                />
                                                            )}
                                                        </div>
                                                        <img
                                                            src="/new-business/won_ring.webp"
                                                            className="won_ring"
                                                            alt="Ada"
                                                        />
                                                        <div className="d-flex justify-content-center">
                                                            <button className="border-0 ada_btn">
                                                                {getOpponent()}
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="quiz_user_image position-relative">
                                                        <div className="choose_quiz_user_image_circle d-flex justify-content-center align-items-center m-auto">
                                                            {selectedUser !==
                                                            "Ada" ? (
                                                                <img
                                                                    src="/new-business/ada.webp"
                                                                    className="position-relative z-3 pt-3"
                                                                    width={80}
                                                                    alt="Ada"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src="/new-business/ommo.webp"
                                                                    className="position-relative z-3 pt-3"
                                                                    width={80}
                                                                    alt="Ommo"
                                                                />
                                                            )}
                                                        </div>
                                                        <img
                                                            src="/new-business/won_ring.webp"
                                                            className="won_ring d-none"
                                                            alt="Ada"
                                                        />
                                                        <div className="d-flex justify-content-center">
                                                            <button className="border-0 ada_btn">
                                                                {getOpponent()}
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <div className="quiz_user_image position-relative">
                                                    <div className="choose_quiz_user_image_circle d-flex justify-content-center align-items-center m-auto">
                                                        {selectedUser !==
                                                        "Ada" ? (
                                                            <img
                                                                src="/new-business/ada.webp"
                                                                className="position-relative z-3 pt-3"
                                                                width={80}
                                                                alt="Ada"
                                                            />
                                                        ) : (
                                                            <img
                                                                src="/new-business/ommo.webp"
                                                                className="position-relative z-3 pt-3"
                                                                width={80}
                                                                alt="Ommo"
                                                            />
                                                        )}
                                                    </div>
                                                    <img
                                                        src="/new-business/won_ring.webp"
                                                        className="won_ring d-none"
                                                        alt="Ada"
                                                    />
                                                    <div className="d-flex justify-content-center">
                                                        <button className="border-0 ada_btn">
                                                            {getOpponent()}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            <p>{result?.botScore} points</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center my-4 my-md-5">
                            <div className="col-12 col-lg-11">
                                <div className="card drag_drop_cards">
                                    <div className="card-body business_results business_public_spaces">
                                        <div className="business_results_right w-100 p-0 text-center">
                                            {result?.percentageScore > 0 && (
                                                <>
                                                    <h4>
                                                        {
                                                            result?.percentageScore
                                                        }
                                                        %
                                                    </h4>
                                                    <h5>Congratulations!</h5>
                                                </>
                                            )}
                                            <h6>
                                                You got{" "}
                                                {result?.userCorrectAnsCount}{" "}
                                                out of {result?.totalQuestion}{" "}
                                                questions
                                            </h6>
                                            <p>
                                                You have successfully completed
                                                the case study, now you can
                                                proceed to the quiz.
                                            </p>
                                            <div className="d-flex justify-content-center align-items-center gap-2">
                                                <h3 className="mb-0 points_earned">
                                                    Points earned:
                                                </h3>
                                                <div className="business_data_privacy_trail">
                                                    <button className="award_btn fw-700 border-0 d-flex align-items-center gap-2">
                                                        <img
                                                            src="/new-business/award-icons.webp"
                                                            width={24}
                                                            alt=""
                                                        />
                                                        {result?.userScore}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center m-auto align-items-center gap-3 right_side_information px-0">
                                                <button
                                                    onClick={handleNavigate}
                                                >
                                                    Replay
                                                </button>
                                                <Link
                                                    to="/trail/facts"
                                                    state={{
                                                        step: true,
                                                        trailId:
                                                            location?.state
                                                                ?.trailId,
                                                        name: location?.state
                                                            ?.name,
                                                    }}
                                                    className="d-flex justify-content-between align-items-center"
                                                >
                                                    Start Facts to Know
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99805L16 12.9995"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </Link>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center mt-4 still_questions">
                                                <span>
                                                    Still have questions?{" "}
                                                    <Link
                                                        to="/trail/ask-to-ai"
                                                        state={{
                                                            trailId:
                                                                location?.state
                                                                    ?.trailId,
                                                            name: location
                                                                ?.state?.name,
                                                        }}
                                                    >
                                                        Click here.
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>
                                        {/* <div className='certificate_text text-center'>
                                            <p>Join the learning adventure</p>
                                            <h4>You want a certificate? It is free.
                                                You just need to sign up.</h4>
                                            <h5>Creating an account is quick, easy, and completely free!</h5>
                                            <h6>Do you want a certificate that you can show to your employer and show on LinkedIn for example?</h6>
                                            <Link to="/business/puzzle" className='d-flex justify-content-between align-items-center'>
                                                Sign Up
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={22}
                                                    height={14}
                                                    viewBox="0 0 22 14"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M1 6.99854H21L16 1"
                                                        stroke="#000C2A"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M21 6.99805L16 12.9995"
                                                        stroke="#000C2A"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default BusinessQuizResults;
