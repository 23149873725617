import React, { useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { getServerURL } from "../../helper/envConfig";
import { toast } from "react-toastify";
import api from "../../helper/api";
import { Modal } from "react-bootstrap";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import { CourseContext } from "../../context/CourseContext";

function SuggestFutureTrainingTopics() {
    const serverURL = getServerURL();
    const [answer, setAnswer] = useState("");
    const { getTrailsTotalPoint } = useContext(CourseContext);
    const [checkBox, setCheckBox] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const onSubmitAnswer = async () => {
        try {
            if (!answer.trim()) {
                toast.error("Please answer the answer!");
                return;
            }
            const response = await api.postWithToken(
                `${serverURL}trails/suggest-future-training/`,
                {
                    user_answer: answer,
                    is_notify: checkBox,
                }
            );
            if (response.data.success) {
                setShow(true);
                await api.postWithToken(`${serverURL}trails/step-progress/`, {
                    trail_step: 9,
                    status: "completed",
                    points_earned: 5,
                    score: 100,
                    trail_id: location?.state?.trailId,
                });

                await api.postWithToken(`${serverURL}trails/point-history/`, {
                    activity_type: "suggest-future-training-topics-completed",
                    activity: "Suggest Future Training Topics Completed",
                    point_gained: 5,
                    point_lost: 0,
                });
                setTimeout(() => {
                    setShow(false);
                    navigate("/trail/share-your-feedback", {
                        state: {
                            step: true,
                            trailId: location.state.trailId,
                            name: location.state.name,
                        },
                    });
                }, 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getTrailsTotalPoint();
        if (!location?.state?.step) {
            navigate("/trail-list");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            <PointsHeader to="/trail/points" />
            <section className="py-5 training_topics">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card border-0 question_card_box">
                                <div className="card-body">
                                    <h3>
                                        Which topic would I like to be getting a
                                        training on?
                                    </h3>
                                    <img src="/login/woman-home.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-xl-5 question_card_details mt-4 mt-md-5 mt-xl-0">
                            <h3>What’s Next for You?</h3>
                            <p>
                                Suggest a topic you’d like to explore in the
                                future.
                            </p>

                            <textarea
                                rows="10"
                                className="form-control pt-2 mt-3 ai-textarea"
                                placeholder="Please provide your answer here."
                                id="floatingTextarea2"
                                value={answer}
                                onChange={(e) => setAnswer(e.target.value)}
                            />

                            <h5>Reminder</h5>
                            <div className="manage-family-checkbox training_topics_checkbox">
                                <div class="form-check mt-3">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked2"
                                        name="username"
                                        checked={checkBox}
                                        onChange={(e) =>
                                            setCheckBox(e.target.checked)
                                        }
                                    />
                                    <label
                                        class="form-check-label"
                                        for="flexCheckChecked2"
                                    >
                                        Notify me when training on this topic
                                        becomes available.
                                    </label>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end pt-4 pb-md-5">
                                <button
                                    onClick={onSubmitAnswer}
                                    className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 mt-md-4"
                                >
                                    Proceed
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={22}
                                        height={14}
                                        viewBox="0 0 22 14"
                                        fill="none"
                                        className="ms-5"
                                    >
                                        <path
                                            d="M1 6.99854H21L16 1"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M21 6.99805L16 12.9995"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                className="congratulations_modal_custom"
                show={show}
                centered
                size="lg"
                onHide={handleClose}
            >
                <Modal.Body>
                    <div className="text-center">
                        <div className="position-relative correct_answers d-flex justify-content-center m-auto">
                            <img
                                src="/new-business/points.webp"
                                className="m-auto d-flex justify-content-center"
                                alt=""
                            />
                            <h5>5</h5>
                        </div>

                        <h3>Congratulations!</h3>
                        <p>
                            +5 Points for Completing a Suggest future training
                            topics!
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    );
}

export default SuggestFutureTrainingTopics;
