import React from 'react';

const InformationSecurity = () => {
    return (
        <>
            <section>
                <div className="page-bg">
                    <div className="container">
                        <h4>Information Security at Unicorso</h4>
                    </div>
                </div>
            </section>

            <section className='information-details'>
                <div className="container">
                    <h6>At Unicorso, safeguarding your data is not just a feature—it's a foundational principle.</h6>

                    <h6>We have implemented a robust security and privacy framework that aligns with international standards like GDPR, ensuring transparency, control, and protection at every step of your journey with us. From secure infrastructure and encrypted data flows to proactive monitoring and access control, your trust is well-placed.
                    </h6>

                    <h5>Key Highlights of Our Information Security Program:</h5>

                    <ul>
                        <li>
                            <span>End-to-End Data Encryption: </span>
                            All data is encrypted —both in storage and in transit.

                        </li>
                        <li>
                            <span>Strict Access Controls: </span>
                            Role-based access and multi-factor authentication (MFA) ensure only authorized personnel can access critical systems.
                        </li>
                        <li>
                            <span>User-Centric Privacy Controls: </span>
                            Users have full transparency and control over their data, including options for export, deletion, and access requests.

                        </li>
                        <li>
                            <span>Resilient Infrastructure: </span>
                            Hosted securely in Germany, our systems are backed by automated backups, disaster recovery strategies, and a high-availability architecture.
                        </li>
                        <li>
                            <span>Business Continuity Ready: </span>
                            Our systems are designed to stay online—even in the face of unexpected events—thanks to our Business Continuity Management (BCM) plan and geographically diverse backup strategy.
                        </li>
                        <li>
                            <span>Compliance-First Mindset: </span>
                            We follow best practices in data classification, incident response, and user consent—ensuring full alignment with GDPR and other privacy regulations.
                        </li>
                    </ul>

                    <h6>Whether you’re an employee, a family, an educator, or institution, you can rely on Unicorso’s platform to keep your data secure and your learning experience uninterrupted.</h6>
                </div>
            </section>
        </>
    );
};

export default InformationSecurity;
