import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { decryptFromStoreLoc } from '../helper/localStore';
import { useNavigate } from 'react-router-dom';
import { CourseContext } from '../context/CourseContext';

const Footer = () => {

    const { categories, user, setEmail } = useContext(CourseContext);

    const [showButton, setShowButton] = useState(false);
    const history = useNavigate()

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Assuming decryptFromStoreLoc returns an array or null/u ndefined
    const subcategories = decryptFromStoreLoc("subcat") || [];


    const redirectSubcategories = (redirect) => {
        history(redirect)
    };


    const getSubcategories = (categoryId, count) => {
        // Find the category by ID
        const category = categories.find(cat => cat.category_id === categoryId);

        // If category exists and has subcategories, slice up to 'count' subcategories
        if (category && category.subcategories) {
            return category.subcategories.slice(0, count);
        }

        return [];
    };

    // Get 4 sub-categories from the category with ID 4 (Compliance)
    const subcategoriesList = getSubcategories(4, 4);

    const handleNavigate = (path) => {
        history(path)
    }

    return (
        <>
            <footer>
                <div className="footer-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-xl-5">
                                <div className="card bg-transparent border-0">
                                    <div className="card-body px-0">
                                                <Link to="/">
                                                    <img
                                                        src="/logo/footer-logo.png"
                                                        className="footer-logo"
                                                        alt=""
                                                    />
                                                </Link>
                                                <div className='footer-btns'>
                                                    <button onClick={()=> handleNavigate("/request/training")}>Request an Online or Onsite Training</button>
                                                    <button onClick={()=> handleNavigate("/request/legal/advice")}>Request Legal Advice</button>
                                                </div>
                                                {!user && !user?.username &&
                                                    <>
                                                    <p className="text-muted fw-400 mt-4">
                                                        Embark on an exciting learning adventure by signing up for our
                                                        platform today! Unlock a world of knowledge, skill-building, and
                                                        personal growth with just a few simple steps.
                                                    </p>
                                                    <div className="d-md-flex justify-content-start m-auto mt-5">
                                                        <div className="subscribe-box d-md-flex justify-content-start">
                                                            <div className="col-12 col-md-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control footer-input rounded-start-2 rounded-end-0 border-0 h-100 ps-3 pe-2"
                                                                    placeholder="Type Your Email Address Here"
                                                                    aria-label="First name"
                                                                    onChange={(e) => {
                                                                        setEmail(e.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                            <button
                                                                onClick={() => redirectSubcategories("/sign-up")}
                                                                type="button"
                                                                className="col-12 col-md-6 btn-primary text-center gap-3 rounded-start-0 rounded-end-2 mt-4 mt-md-0"
                                                            >
                                                                Enroll Today
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_250_9685"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={24}
                                                                        height={24}
                                                                    >
                                                                        <rect width={24} height={24} fill="#D9D9D9" />
                                                                    </mask>
                                                                    <g mask="url(#mask0_250_9685)">
                                                                        <path
                                                                            d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                            fill="white"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </> }
                                        <div className="social-icon d-flex align-items-center gap-4 pt-5">
                                            <Link to="https://www.linkedin.com/company/unicorso">
                                                <img src="/png/linkedin.png" alt="" />
                                            </Link>
                                            <img src="/png/instagram.png" alt="" />
                                            <img src="/png/facebook.png" alt="" />
                                            <img src="/png/twitter.png" alt="" />
                                            <img src="/png/email.png" alt="" />
                                        </div>
                                        <div className='d-flex justify-content-center gap-3'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-7">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card bg-transparent border-0">
                                            <div className="card-body px-0">
                                                {/* <h2 className="text-black fw-400">Unicorso Courses</h2>
                                                <ul className="footer-btn-list mt-4 p-0 mb-0"> */}

                                                {/* {subcategories && subcategories?.map((subcategory, index) => (

                                                        <li key={index} >
                                                            <button
                                                                onClick={() => redirectSubcategories(`/courses-category/${subcategory.replace(/ /g, '-')}`)}
                                                                type="button"
                                                                className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                            >
                                                                {subcategory}
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_250_9727"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={24}
                                                                        height={24}
                                                                    >
                                                                        <rect width={24} height={24} fill="#D9D9D9" />
                                                                    </mask>
                                                                    <g mask="url(#mask0_250_9727)">
                                                                        <path
                                                                            d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                            fill="#002FA7"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    ))} */}

                                                {/* {subcategories && subcategories.map((subcategory, index) => (
                                                        <li key={index}>
                                                            <button
                                                                onClick={() => redirectSubcategories(`/courses-category/${subcategory.replace(/ /g, '-')}`)}
                                                                type="button"
                                                                className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                            >
                                                                {subcategory?.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <mask
                                                                        id="mask0_250_9727"
                                                                        style={{ maskType: "alpha" }}
                                                                        maskUnits="userSpaceOnUse"
                                                                        x={0}
                                                                        y={0}
                                                                        width={24}
                                                                        height={24}
                                                                    >
                                                                        <rect width={24} height={24} fill="#D9D9D9" />
                                                                    </mask>
                                                                    <g mask="url(#mask0_250_9727)">
                                                                        <path
                                                                            d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                            fill="#002FA7"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    ))} */}

                                                {/* {subcategories.length <= 0
                                                        && <>

                                                            {subcategoriesList && subcategoriesList.map((subcategory, index) => (
                                                                <li key={index}>
                                                                    <button
                                                                        onClick={() => redirectSubcategories(`/courses-category/${subcategory.name?.toLowerCase().replace(/ /g, '-')}`)}
                                                                        type="button"
                                                                        className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                                    >
                                                                        {subcategory?.name?.split('-').map(word => word.charAt(0)?.toUpperCase() + word?.slice(1)).join(' ')}
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={24}
                                                                            height={24}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <mask
                                                                                id="mask0_250_9727"
                                                                                style={{ maskType: "alpha" }}
                                                                                maskUnits="userSpaceOnUse"
                                                                                x={0}
                                                                                y={0}
                                                                                width={24}
                                                                                height={24}
                                                                            >
                                                                                <rect width={24} height={24} fill="#D9D9D9" />
                                                                            </mask>
                                                                            <g mask="url(#mask0_250_9727)">
                                                                                <path
                                                                                    d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                                    fill="#002FA7"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                    </button>
                                                                </li>
                                                            ))}

                                                        </>
                                                    } */}
                                                {/* <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Personal Development
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Health and Wellness
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Environmental Sustainability
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Education
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Employment and Legal
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Social Issues and Ethics
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Marketing and Sales
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li> */}
                                                {/* </ul> */}
                                                {/* <h2 className="text-black fw-400 mt-4">Quick Links</h2> */}
                                                {/* <ul className="footer-btn-list mt-4 p-0">
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            For Individuals
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            For Teams
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Reviews
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Community
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            FAQs
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Help Center
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            About Us
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Contact Us
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                </ul> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-7">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card bg-transparent border-0">
                                            <div className="card-body px-0">
                                                {/* <h2 className="text-black fw-400 mt-4">Quick Links</h2> */}
                                                <ul className="footer-btn-list mt-0 mt-md-4 p-0">
                                                    <li>
                                                        <button
                                                            type="button"
                                                            onClick={() => redirectSubcategories(`/imprint`)}
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Imprint
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            onClick={() => redirectSubcategories(`/data-privacy`)}
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Data Privacy
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            onClick={() => redirectSubcategories("/terms-condition")}
                                                            className="btn-primary bg-transparent d-flex align-items-center text-primary gap-2 rounded-0"
                                                        >
                                                            Terms
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_250_9727"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_250_9727)">
                                                                    <path
                                                                        d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                                                                        fill="#002FA7"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className='footer-bottom'>
                <p>
                    If you want to leave any positive or negative feedback, you can always mail to
                    <a href="mailto:feedback@unicorso.com"> feedback@unicorso.com </a>
                    – We are thankful for any feedback.
                </p>
            </div>


            <div>
                {showButton && (
                    <button className="show" id='button' onClick={scrollToTop}>
                        <img src="/svg/up-arrow.svg" alt="" />
                    </button>
                )}
            </div>

        </>
    );
}

export default Footer;
