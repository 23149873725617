import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import "../../styles/newbusiness.css";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { decryptFromStore, encryptAndStore } from "../../helper/sessionStore";
import { toast } from "react-toastify";

const BusinessQuizChooseGuide = () => {
    const [activeUser, setActiveUser] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const location = useLocation()
    const navigate = useNavigate()

    const handleNext = () => {
        if (!activeUser) {
            toast.error("Please select a user to proceed");
            return;
        }
        setCurrentStep((prevStep) => (prevStep === 1 ? 2 : prevStep));
        window.scrollTo(0, 0);
    };

    const handleBack = () => {
        setCurrentStep(1);
        window.scrollTo(0, 0);
    };

    // Function to handle setting the active user
    const handleUserClick = (user) => {
        setActiveUser(user);
        encryptAndStore("selectedUser", {
            user: user,
        });
    };

    useEffect(() => {
        sessionStorage.clear()
        if(!location?.state?.step) {
            navigate("/trail-list")
        }
        const getUser = decryptFromStore("selectedUser");
        if (getUser?.user) {
            setActiveUser(getUser.user);
        }
    }, []);

    return (
        <>
            <Header />
            <main className="business_puzzle business_choose_quiz">
                <section>
                    <PointsHeader to="/trail/points" />
                </section>
                <section className="business_puzzle ">
                    <div className="container">
                        <div>
                            {currentStep === 1 && (
                                <div className="step-1">
                                    <div className="puzzle_top_box">
                                        <div className="row">
                                            <div className="col-8">
                                                <span>Quiz</span>
                                                <h2>
                                                    Choose Your Virtual Guide
                                                </h2>
                                                <p>
                                                    Choose Your Guide: Ada or
                                                    Ommo.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4 mt-lg-5 choose_quiz_user_box">
                                        <div className="col-12 col-lg-11">
                                            <div className="card mb-4 mb-lg-5">
                                                <div className="card-body p-0">
                                                    <div className="d-flex justify-content-evenly align-items-center">
                                                        <div className="choose_quiz_user_image">
                                                            <div
                                                                className={`choose_quiz_user_image_circle p-2 ${
                                                                    activeUser ===
                                                                    "Ada"
                                                                        ? "active"
                                                                        : ""
                                                                }`}
                                                                onClick={() =>
                                                                    handleUserClick(
                                                                        "Ada"
                                                                    )
                                                                }
                                                            >
                                                                <div className="bg-secondary w-100 h-100 d-flex justify-content-center align-items-center rounded-circle">
                                                                    <img
                                                                        src="/new-business/ada.webp"
                                                                        alt="Ada"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p>Ada</p>
                                                        </div>

                                                        <div className="choose_quiz_user_image">
                                                            <div
                                                                className={`choose_quiz_user_image_circle p-2 ${
                                                                    activeUser ===
                                                                    "Ommo"
                                                                        ? "active"
                                                                        : ""
                                                                }`}
                                                                onClick={() =>
                                                                    handleUserClick(
                                                                        "Ommo"
                                                                    )
                                                                }
                                                            >
                                                                <div className="bg-secondary w-100 h-100 d-flex justify-content-center align-items-center rounded-circle">
                                                                    <img
                                                                        src="/new-business/ommo.webp"
                                                                        alt="Ommo"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p>Ommo</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {currentStep === 2 && (
                                <div className="step-2">
                                    <div className="puzzle_top_box">
                                        <div className="row">
                                            <div className="col-8">
                                                <span>Quiz</span>
                                                <h2>How To Play</h2>
                                                <p>
                                                    Explore the rules of our
                                                    game before continuing.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-5 choose_quiz_user_box">
                                        <div className="col-12 col-lg-11">
                                            <div className="card mb-5">
                                                <div className="card-body">
                                                    <div className="row rules_box text-center g-3">
                                                        <div className="col-12 col-md-6 col-lg-4">
                                                            <img
                                                                src="/new-business/rules-1.webp"
                                                                className="pb-3"
                                                                width={162.2}
                                                                height={276}
                                                                alt=""
                                                            />
                                                            <p>
                                                                The goal is to
                                                                have more points
                                                                than the
                                                                opponent and at
                                                                least 7
                                                                questions right.
                                                                If you have
                                                                seven right, you
                                                                have the
                                                                possibility to
                                                                give up.
                                                            </p>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-4">
                                                            <img
                                                                src="/new-business/rules-2.webp"
                                                                className="pb-3"
                                                                width={162.2}
                                                                height={276}
                                                                alt=""
                                                            />
                                                            <p>
                                                                For each wrong
                                                                answer, the
                                                                opponent gains
                                                                double the
                                                                question’s
                                                                value.
                                                            </p>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-4">
                                                            <img
                                                                src="/new-business/rules-3.webp"
                                                                className="pb-3"
                                                                width={162.2}
                                                                height={276}
                                                                alt=""
                                                            />
                                                            <p>
                                                                Players can use
                                                                a joker to skip
                                                                a difficult
                                                                question or stop
                                                                playing at any
                                                                time to keep
                                                                their current
                                                                points.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="d-flex justify-content-end align-items-center gap-3 pb-5">
                                {/* <button
                                    className="border-0"
                                    onClick={handleBack}
                                >
                                    Back
                                </button> */}
                                {currentStep === 1 ? (
                                    <button
                                        className="border-0"
                                        onClick={handleNext}
                                    >
                                        Next
                                    </button>
                                ) : (
                                    <Link
                                        to="/trail/gamified-quiz-start"
                                        state={{
                                            trailId: location?.state?.trailId,
                                            name: location?.state?.name
                                        }}
                                        className="border-0"
                                    >
                                        Let's Play!
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default BusinessQuizChooseGuide;
