import { React, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import { validate } from './LoginSchema';
import { getServerURL } from '../../helper/envConfig'
import { useNavigate , useLocation } from 'react-router-dom'
import api from '../../helper/api'
import { toast } from 'react-toastify';
import { decryptFromStoreLoc, encryptAndStoreLoc } from '../../helper/localStore'
import { encryptAndStore } from '../../helper/sessionStore';

const SignIn = () => {

    const serverURL = getServerURL();
    const history = useNavigate(); // Get the history object for redirection
    const location = useLocation();
    const [result, setResult] = useState();
    const initialValues = {
        email: "",
        password: "",
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [submitCount, setSubmitCount] = useState(0);

    useEffect(() => {

        const decryptedInfoResult = decryptFromStoreLoc('result');
        setResult(decryptedInfoResult)

    }, []);


    const handleChange = (e) => {

        const { name, value, checked, type } = e.target;
        let newValue = type === "checkbox" ? checked : value;

        if (submitCount > 0) {
            const validationErrors = validate({ ...values, [name]: newValue });
            setErrors(validationErrors);

            // Remove error message for the specific field if it is valid
            if (Object.keys(validationErrors).length === 0) {
                delete errors[name];
            }
        }

        setValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));

    };


    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e)
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedValues = { ...values }; // Create a copy of the values object

        const validationErrors = validate(updatedValues);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {

            try {

                const response = await api.post(`${serverURL}users/login/`, updatedValues);

                if (response?.data.success === true) {

                    const paymentData = { status: response.data?.is_plan_active }
                    encryptAndStoreLoc("paymentData", paymentData)

                    if (response.data?.data?.is_one_time_password) {
                        encryptAndStoreLoc("user",response.data.data)
                        history("/change-password")
                        return;
                    }

                    if(response.data?.data?.role === 11 && response.data?.data?.is_adults && response.data?.data?.kids_status === "not-send") {
                            encryptAndStoreLoc("userId",response.data.data)
                            encryptAndStoreLoc("user",response.data.data)
                            localStorage.setItem("kidsToken", response?.data?.token.access);
                            history("/kids-age-confirm")
                            return;
                    }
                    if(response.data?.data?.role === 11 && !response.data?.data?.is_adults && response.data?.data?.kids_status === "rejected") {
                        localStorage.removeItem("token")
                        localStorage.removeItem("user")
                        localStorage.removeItem("userId")
                        history("/parentsshouldallowkidstobepartofunicorso")
                        return;
                    }

                    localStorage.setItem("token", response?.data?.token.access);
                    encryptAndStoreLoc("user", response?.data?.data);

                    if (result?.result_id) {
                        var response2 = await api.postWithToken(`${serverURL}quizzes/send-certificate/`, {
                            "user_id": values?.user_id || response?.data?.data.user_id,
                            "result_id": result?.result_id
                        });
                        toast.success(response2?.data?.message);
                    } else {
                        toast.success(response?.data?.message);
                    }

                    localStorage.removeItem('result');
                    localStorage.removeItem('quiz');
                    localStorage.removeItem('subcat');
                    sessionStorage.clear();


                    if (response.data?.data?.is_associated_with_other) {

                        if (response.data?.data?.is_associated) {

                            if(location?.state?.from === "feedback" && location?.state?.course_id) {
                                handleFeedback()
                                return;
                            }

                            if (response.data?.data?.main_role && response.data?.data.main_role !== undefined && response.data?.is_main_role) {
                                if (response.data?.data?.main_role == 1) {
                                    if(response.data?.data?.role === 12 && !response.data?.is_plan_active) {
                                        setTimeout(() => {
                                            history("/business-free-environment")
                                            window.location.reload()
                                        }, 2000);
                                    } else {
                                        setTimeout(() => {
                                            history("/business-home")
                                            window.location.reload()
                                        }, 2000); // 2000 milliseconds = 2 seconds
                                    }
                                } else if (response.data?.data?.main_role == 2) {
                                    if(!response.data?.is_plan_active) {
                                        setTimeout(() => {
                                            history("/subscription-plans")
                                            window.location.reload()
                                        }, 2000);
                                    } else {
                                        setTimeout(() => {
                                            history("/customer-home")
                                            window.location.reload()
                                        }, 2000); // 2000 milliseconds = 2 seconds
                                    }
                                } else if (response.data?.data?.main_role == 7) {
                                    if(response.data?.data?.role === 13 && !response.data?.is_plan_active) {
                                        setTimeout(() => {
                                            history("/family-user-plan")
                                            window.location.reload()
                                        }, 2000);
                                    } else {
                                        setTimeout(() => {
                                            history("/business-family")
                                            window.location.reload()
                                        }, 2000); // 2000 milliseconds = 2 seconds
                                    }
                                }
                            } else {

                                if (response.data?.data?.role == 1 || response.data?.data?.role == 10 || response.data?.data?.role == 12 || response.data?.data?.role == 8 || response.data?.data?.role == 9) {
                                    if(response.data?.data?.role === 12 && !response.data?.is_plan_active) {
                                        setTimeout(() => {
                                            history("/business-free-environment")
                                            window.location.reload()
                                        }, 2000);
                                    } else {
                                        setTimeout(() => {
                                            history("/business-home")
                                            window.location.reload()
                                        }, 2000); // 2000 milliseconds = 2 seconds
                                    }
                                } else if (response.data?.data?.role == 2) {
                                    if(!response.data?.is_plan_active) {
                                        setTimeout(() => {
                                            history("/subscription-plans")
                                            window.location.reload()
                                        }, 2000);
                                    } else {
                                        setTimeout(() => {
                                            history("/customer-home")
                                            window.location.reload()
                                        }, 2000); // 2000 milliseconds = 2 seconds
                                    }
                                } else if (response.data?.data?.role == 6 || response.data?.data?.role == 11 || response.data?.data?.role == 13) {
                                    if(response.data?.data?.role === 13 && !response.data?.is_plan_active) {
                                        setTimeout(() => {
                                            history("/family-user-plan")
                                            window.location.reload()
                                        }, 2000);
                                    } else {
                                        setTimeout(() => {
                                            history("/business-family")
                                            window.location.reload()
                                        }, 2000); // 2000 milliseconds = 2 seconds
                                    }
                                }

                            }

                        } else {
                            history("/update-associated-code")
                        }

                    } else {

                        if(location?.state?.from === "feedback" && location?.state?.course_id) {
                            handleFeedback()
                            return;
                        }

                        if (response.data?.data?.main_role && response.data?.data.main_role !== undefined) {
                            if (response.data?.data?.main_role == 1) {
                                if(response.data?.data?.role === 12 && !response.data?.is_plan_active) {
                                    setTimeout(() => {
                                        history("/business-free-environment")
                                        window.location.reload()
                                    }, 2000);
                                } else {
                                    setTimeout(() => {
                                        history("/business-home")
                                        window.location.reload()
                                    }, 2000); // 2000 milliseconds = 2 seconds
                                }
                            } else if (response.data?.data?.main_role == 2) {
                                if(!response.data?.is_plan_active) {
                                    setTimeout(() => {
                                        history("/subscription-plans")
                                        window.location.reload()
                                    }, 2000);
                                } else {
                                    setTimeout(() => {
                                        history("/customer-home")
                                        window.location.reload()
                                    }, 2000); // 2000 milliseconds = 2 seconds
                                }
                            } else if (response.data?.data?.main_role == 7) {
                                if(response.data?.data?.role === 13 && !response.data?.is_plan_active) {
                                    setTimeout(() => {
                                        history("/family-user-plan")
                                        window.location.reload()
                                    }, 2000);
                                } else {
                                    setTimeout(() => {
                                        history("/business-family")
                                        window.location.reload()
                                    }, 2000); // 2000 milliseconds = 2 seconds
                                }
                            }
                        } else {

                            if (response.data?.data?.role == 1 || response.data?.data?.role == 10 || response.data?.data?.role == 12) {
                                if(response.data?.data?.role === 12 && !response.data?.is_plan_active) {
                                    setTimeout(() => {
                                        history("/business-free-environment")
                                        window.location.reload()
                                    }, 2000);
                                } else {
                                    setTimeout(() => {
                                        history("/business-home")
                                        window.location.reload()
                                    }, 2000); // 2000 milliseconds = 2 seconds
                                }
                            } else if (response.data?.data?.role == 2) {
                                if(!response.data?.is_plan_active) {
                                    setTimeout(() => {
                                        history("/subscription-plans")
                                        window.location.reload()
                                    }, 2000);
                                } else {
                                    setTimeout(() => {
                                        history("/customer-home")
                                        window.location.reload()
                                    }, 2000); // 2000 milliseconds = 2 seconds
                                }
                            } else if (response.data?.data?.role == 6 || response.data?.data?.role == 11 || response.data?.data?.role == 13 ) {
                                if(response.data?.data?.role === 13 && !response.data?.is_plan_active) {
                                    setTimeout(() => {
                                        history("/family-user-plan")
                                        window.location.reload()
                                    }, 2000);
                                } else {
                                    setTimeout(() => {
                                        history("/business-family")
                                        window.location.reload()
                                    }, 2000); // 2000 milliseconds = 2 seconds
                                }
                            }

                        }
                    }


                } else if (response?.data?.success === false) {
                    encryptAndStoreLoc("user", response?.data?.data);
                    toast.success(response?.data.message, { type: toast.TYPE.INFO });
                    history("/verify-email")
                }

            } catch (error) {
                toast.error(error.response?.data.message);
            }
        }
    };

    // Redirect to feedback page if login from feedback
     const handleFeedback = () => {
        encryptAndStore('feedback', {
            id: location?.state?.course_id
        });
        setTimeout(() => {
            history("/feedback")
            window.location.reload()
        }, 2000); // 2000 milliseconds = 2 seconds
     }



    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col vh-md-100">
                                <img
                                    src="./login/individual-user-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/woman-home.webp"
                                    className="project-team"
                                    alt=""
                                />
                                <img
                                    src="./login/individual-user-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 position-relative d-flex justify-content-center align-items-start align-items-md-end sign-up-col sign-up-col-sm vh-md-100 pb-md-5">
                                <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                                    <form className="row g-3 form-main">
                                        <div className="col-md-12">
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label className="form-label">Email Address</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Enter your email address"
                                                    name="email"
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    type='email'
                                                />
                                                <div className='error' >{errors?.email}</div>
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 mb-5">
                                                <label className="form-label">Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="inputPassword4"
                                                    name='password' value={values.password}
                                                    onChange={handleChange}
                                                    placeholder="Enter your password"
                                                    onKeyPress={handleKeyPress}
                                                />
                                                <div className='error' >{errors?.password}</div>
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-8 d-flex justify-content-end gap-3 pt-md-12">
                                                <Link
                                                    to="/sign-up"
                                                    className="btn-explore rounded-2 fw-400"
                                                >
                                                    Sign Up
                                                </Link>
                                                <button
                                                    type='button'
                                                    className="btn-primary rounded-2 fw-400"
                                                    onClick={(e) => (handleSubmit(e), setSubmitCount(1))
                                                    }                                                >
                                                    Sign In
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default SignIn