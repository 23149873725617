/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import "../../styles/newbusiness.css";
import PointsHeader from '../../components/NewBusiness/PointsHeader';
import CountdownTimer from '../../components/NewBusiness/CountdownTimer';
import { Modal } from 'react-bootstrap';
import ReportAnIssue from '../../components/NewBusiness/ReportAnIssue';
import { CourseContext } from '../../context/CourseContext';
import { getServerURL } from '../../helper/envConfig';
import api from '../../helper/api';
import { toast } from 'react-toastify';
import CryptoJS from "crypto-js";
import { decryptFromStore, encryptAndStore } from '../../helper/sessionStore';
import { useLocation, useNavigate } from 'react-router-dom';



const BusinessQuiz = () => {
    const key = process.env.REACT_APP_CRYPTO_PY_KEY;
    const serverURL = getServerURL()
    const navigate = useNavigate()
    const location = useLocation();
    const { pointsData, getTrailsTotalPoint, user } = useContext(CourseContext);
    const [activeUser, setActiveUser] = useState(null);
    const [quizData, setQuizData] = useState({})
    const [activeIndex, setActiveIndex] = useState(0)
    const [selectedRadio, setSelectedRadio] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const [show, setShow] = useState(false);  // Modal visibility state
    const [buttonClicked, setButtonClicked] = useState(false);  // To trigger button animation
    const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
    const [correctAnswerId, setCorrectAnswerId] = useState(false);
    const [questionPoint, setQuestionPoint] = useState(1);
    const [userAnswers, setUserAnswers] = useState([])
    const [botAnswers, setBotAnswers] = useState([])
    const [userLiveScore, setUserLiveScore] = useState(0)
    const [botLiveScore, setBotLiveScore] = useState(0)
    const [usedJoker, setUsedJoker] = useState(0)
    const [jokerPair, setJokerPair] = useState([])
    let userScore = 0; // Tracks the user's score
    let botScore = 0; // Tracks the bot's score
    const letters = ['A.', 'B.', 'C.', 'D.'];
    const optionsChar = ['A', 'B', 'C', 'D'];
    const childRef = useRef();

    //  setTime in timer component on change question
    const updateChildState = () => {
        // Directly calling the child component's method to set state
        childRef.current.setTimeLeft(59);
    };

    // Handle radio button change
    const handleRadioChange = (e) => {
        setSelectedRadio(e.target.id);
    };

    const handleClose = () => setShow(false);

    const getQuizData = async() => {
        try {
            const type = ( user.main_role === 1 || user.role === 2) ? "business" : "kid";
            const response = await api.getWithToken(serverURL + `trails/quiz/?type=${type}&trail_id=${location?.state?.trailId}`)
            if (response.data.success) {
                setQuizData(response.data.data[0]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if(!pointsData?.total_point_earned) {
            getTrailsTotalPoint()
        }
        getQuizData()
        const getUser = decryptFromStore("selectedUser")
        if(getUser?.user) {
            setActiveUser(getUser.user);
            const data = decryptFromStore("gamifiedData")
            const joker = decryptFromStore("joker")
            if(joker) {
                setUsedJoker(joker.used)
            }
            if(data) {
                // set all state to resume were left
                setActiveIndex(data.activeIndex)
                setButtonClicked(data.buttonClicked)
                setIsSubmit(data.isSubmit)
                setSelectedRadio(data.selectedRadio)
                setIsCorrectAnswer(data.isCorrectAnswer)
                setCorrectAnswerId(data.correctAnswerId)
                setUserAnswers(data.userAnswers)
                setBotAnswers(data.botAnswers)
                setUserLiveScore(data.userLiveScore)
                setBotLiveScore(data.botLiveScore)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (auto) => {
        if (!auto) {
            if (!selectedRadio) {
                toast.error("Please select an answer.");
                return;
            }
        }
        if(activeIndex + 1 > quizData?.trai_quiz_question.length) {
            return
        }

        setIsSubmit(true)
        const ans = quizData?.trai_quiz_question[activeIndex].id
        let keydata = CryptoJS.enc.Utf8.parse(key);
        var decrypted = CryptoJS.AES.decrypt(ans, keydata, {
            mode: CryptoJS.mode.ECB,
        });
        const answer = decrypted.toString(CryptoJS.enc.Utf8);
        setCorrectAnswerId(answer)
        const questionScore = quizData?.trai_quiz_question[activeIndex].point

        let botScore = botLiveScore
        let usScore = userLiveScore

        let botAns
        const userAns = [...userAnswers]
        // eslint-disable-next-line eqeqeq
        if( answer == selectedRadio) {
            setIsCorrectAnswer(true);
            setQuestionPoint(quizData?.trai_quiz_question[activeIndex].point)
            userAns.push(true)
            botAns = getBotAnswer(activeIndex, true)
            setUserLiveScore((score) =>  score + questionScore)
            usScore += questionScore
        } else {
            userAns.push(false)
            setUserAnswers(userAns)
            botAns = getBotAnswer(activeIndex, false)
            setBotLiveScore((score) =>  score + (questionScore * 2))
            botScore += (questionScore * 2)
        }
        setUserAnswers(userAns)


        if(!auto) {
            setShow(true);
        }
        setButtonClicked(true);
        setTimeout(async () => {
            const btAnswers = [...botAnswers]
            btAnswers.push(botAns)
            setBotAnswers(btAnswers)

            if(botAns) {
                setBotLiveScore((score) =>  score + questionScore)
                botScore +=  questionScore
            } else {
                setUserLiveScore((score) =>  score + (questionScore * 2))
                usScore += (questionScore * 2)
            }
            setShow(false);
            setButtonClicked(false); // Reset the button animation state
            setSelectedRadio(null)
            setIsCorrectAnswer(false);
            setIsSubmit(false)

            if(quizData?.trai_quiz_question.length - 1 !== activeIndex ) {
                setActiveIndex(activeIndex + 1)
                setCorrectAnswerId(null)
                updateChildState()

                // set data to session store , restore when user refresh the  page
                const obj = {
                    activeIndex: activeIndex + 1,
                    buttonClicked: false,
                    isSubmit: false,
                    selectedRadio: null,
                    isCorrectAnswer: false,
                    correctAnswerId: null,
                    userAnswers: userAns,
                    botAnswers: btAnswers,
                    userLiveScore: usScore,
                    botLiveScore: botScore
                }
                encryptAndStore("gamifiedData", obj)
                setJokerPair([])

            } else {
                const userCorrectAnsCount = userAns.filter((ans) =>  ans ===  true).length
                const percentageScore = (userCorrectAnsCount / quizData?.trai_quiz_question.length) * 100;
                await api.postWithToken(
                    `${serverURL}trails/step-progress/`,
                    {
                        trail_step: 4,
                        status: "completed",
                        points_earned: usScore,
                        score: percentageScore,
                        trail_id: location?.state?.trailId,
                    }
                );
                if(usScore !== 0) {
                    await api.postWithToken(
                        `${serverURL}trails/point-history/`,
                        {
                            activity_type: "gamified-quiz-completed",
                            activity: "Gamified Quiz Completed",
                            point_gained: usScore,
                            point_lost: 0,
                        }
                    );
                }
                const result = {
                    totalQuestion: quizData?.trai_quiz_question.length,
                    userCorrectAnsCount: userCorrectAnsCount,
                    percentageScore: percentageScore,
                    userScore: usScore,
                    botScore: botScore,
                    userAnswers: userAns,
                    winner: usScore === botScore ? "" : usScore > botScore ? activeUser : getOpponent(),
                    isTie: usScore === botScore ? true: false
                }
                encryptAndStore("gamifiedResult", result)
                sessionStorage.removeItem("gamifiedData")
                sessionStorage.removeItem("joker")
                navigate("/trail/gamified-quiz-results", {
                    state: {
                        trailId: location?.state?.trailId,
                        name: location?.state?.name
                    },
                })
            }
        }, 1500);
    }

    // Randomly decide if the bot is set to win (10% chance)
    const botWinMode = Math.random() < 0.1;

    // Function to decide the bot's answer
    function getBotAnswer(questionNumber, userAnswerCorrect) {
        const randomChance = Math.random();

        if (botWinMode) {
            // Bot is set to win
            if (questionNumber <= 8) {
                // Early and middle phases: stay competitive
                if (userScore >= botScore) {
                    botScore++; // Bot ensures it stays ahead
                    return true;
                } else {
                    return randomChance < 0.5; // Occasionally get it wrong
                }
            } else {
                // Final phase: ensure bot wins
                botScore++;
                return true;
            }
        } else {
            // Bot is not set to win
            if (questionNumber <= 3) {
                // Early phase: mimic user
                if (userAnswerCorrect) {
                    botScore++;
                    return true;
                } else {
                    return false;
                }
            } else if (questionNumber <= 8) {
                // Middle phase: adjust to keep it competitive
                if (userScore > botScore) {
                    if (randomChance < 0.8) {
                        botScore++;
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (randomChance < 0.6) {
                        return false;
                    } else {
                        botScore++;
                        return true;
                    }
                }
            } else {
                // Final phase: 50-50 chance to tie or user wins
                if (userScore >= botScore) {
                    return randomChance < 0.5; // Decide tie or user win
                } else {
                    return false; // Let user catch up
                }
            }
        }
    }

    function getOpponent () {
        if(activeUser === "Ada") {
            return "Ommo";
        }
        if(activeUser === "Ommo") {
            return "Ada";
        }
    }

    function getRandomPair(excludedValue) {
        // All possible pairs from the values "A", "B", "C", "D"
        const possiblePairs = [
            ["A", "B"], ["A", "C"], ["A", "D"],
            ["B", "C"], ["B", "D"],
            ["C", "D"]
        ];

        // Filter out pairs that contain the excluded value
        const filteredPairs = possiblePairs.filter(pair => 
            !pair.includes(excludedValue)
        );

        // Return a random pair from the filtered list, or null if no valid pairs
        if (filteredPairs.length > 0) {
            const randomIndex = Math.floor(Math.random() * filteredPairs.length);
            return filteredPairs[randomIndex];
        } else {
            return null; // No valid pair remaining
        }
    }

    const handleJokerClick = () => {
        if(usedJoker === quizData.joker){
           toast.error("Oops! You've reached your Joker limit. You cannot use it anymore for this quiz.")
           return
        }
        const ans = quizData?.trai_quiz_question[activeIndex].id
        let keydata = CryptoJS.enc.Utf8.parse(key);
        var decrypted = CryptoJS.AES.decrypt(ans, keydata, {
            mode: CryptoJS.mode.ECB,
        });
        const answer = decrypted.toString(CryptoJS.enc.Utf8);
        const indexOfAnswer = quizData?.trai_quiz_question[activeIndex].trail_quiz_answer.findIndex(item => item.answer_id === Number(answer));
        const jokerPair = getRandomPair(optionsChar[indexOfAnswer])
        setJokerPair(jokerPair)
        const use = usedJoker + 1
        setUsedJoker(use)
        encryptAndStore("joker" , {used: use})
    }

    function getCheckBoxValue (index, selected, answer, correctAnswerId) {
        let val = false;
        if(jokerPair.includes(optionsChar[index])) {
            val = true
        }
        if(Number(selected) === Number(answer)) {
            val = true
        }

        if(Number(selected) !== Number(answer) && Number(answer) === Number(correctAnswerId)) {
            val = true
        }
        return val
    }


    return (
        <>
            <Header />
            <main className='business_quiz_main'>
                <section>
                    <PointsHeader to="/trail/points" />
                </section>
                <section>
                    <div className="container">
                        <div className="bg_quiz_box"></div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-11">
                                <div className="card mb-5">
                                    <div className="card-body p-0">
                                        <div className="row">
                                            <div className="col-6 col-md-2 text-center quiz_user order-1">
                                                <h6>You</h6>
                                                <div className='quiz_user_image'>
                                                    <div className="choose_quiz_user_image_circle d-flex justify-content-center align-items-center m-auto">
                                                        { activeUser === "Ada" ? <img src="/new-business/ada.webp" className='position-relative z-3 pt-3' width={80} alt="Ada" />
                                                            : <img src="/new-business/ommo.webp" className='position-relative z-3 pt-3' width={80} alt="Ada" /> }
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        <button className='border-0 ada_btn'>{activeUser}</button>
                                                    </div>
                                                </div>
                                                <p>1 points</p>
                                                <div className='points_line d-flex flex-wrap justify-content-center align-items-center m-auto gap-1'>
                                                    {userAnswers?.map((answer, index) => (
                                                        <div key={index} className={answer ? 'right_line' : 'wrong_line'}></div>
                                                    ))}
                                                    {[1,2,3,4,5,6,7,8,9,10].splice(userAnswers.length)?.map((answer, index) => (
                                                        <div key={index}></div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-8 order-3 order-md-2">
                                                <div className='question_timer_mian d-flex justify-content-between align-items-center gap-4 m-auto'>
                                                    <div className='point_right_text d-flex align-items-center gap-2'>{userLiveScore} <div className="point_right"></div></div>
                                                    <div className='question_counter'>
                                                        <CountdownTimer handleSubmit={handleSubmit}  ref={childRef}/>
                                                        <p>Question {activeIndex + 1}/10</p>
                                                    </div>
                                                    <div className='point_wrong_text d-flex align-items-center gap-2'>{botLiveScore} <div className="point_wrong"></div></div>
                                                </div>
                                                <p className='question'>
                                                    {quizData && quizData?.trai_quiz_question && quizData?.trai_quiz_question[activeIndex]?.question}
                                                    <span className='question_point'>{" "} ( { quizData && quizData?.trai_quiz_question && quizData?.trai_quiz_question[activeIndex]?.point} point) {" "}</span>
                                                    <ReportAnIssue  trailStep={4} questionId={quizData?.trai_quiz_question && quizData?.trai_quiz_question[activeIndex]?.question_id  }/>
                                                </p>
                                                <div className='business_public_question'>

                                                    <div className='row g-3 g-xl-4 question_list'>
                                                        {quizData?.trai_quiz_question?.length > 0 &&
                                                            quizData?.trai_quiz_question[activeIndex].trail_quiz_answer.map((answer, index) => (
                                                                <div className="col-12 col-lg-6" key={index}>
                                                                    <label
                                                                        className={`form-check-label d-inline-flex justify-content-between align-items-center w-100 gap-4 mb-0
                                                                        ${(isSubmit && selectedRadio == answer.answer_id && correctAnswerId == answer.answer_id) ? 'right' : (isSubmit && selectedRadio == answer.answer_id && correctAnswerId != answer.answer_id) ? "wrong" : (selectedRadio == answer.answer_id) ? "selected" : (correctAnswerId == answer.answer_id) ? "right" : "" }   ${jokerPair.includes(optionsChar[index]) ? "wrong selected" : ""} `}
                                                                        htmlFor={answer.answer_id} key={index}
                                                                    >
                                                                        <p className='pb-0 mb-0'>
                                                                            {letters[index]} {answer.answer}
                                                                        </p>

                                                                        <input
                                                                            className="form-check-input float-none m-0"
                                                                            type="checkbox"
                                                                            name="flexRadioDefault"
                                                                            id={answer.answer_id}
                                                                            // checked={(jokerPair.length > 0 && jokerPair.includes(optionsChar[index])) || selectedRadio == answer.answer_id }
                                                                            checked={getCheckBoxValue(index, selectedRadio, answer.answer_id, correctAnswerId) }
                                                                            onChange={handleRadioChange}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>

                                                <div className='use_joker_box d-flex justify-content-center align-items-start gap-3'>
                                                    <div>
                                                        <div className='d-flex align-items-center gap-3 mb-2'>
                                                            <img src="/new-business/jokar.webp" width={25} height={25} alt="" />
                                                            <span className='cursor-pointer' onClick={handleJokerClick}>Use Joker</span>
                                                        </div>
                                                        <p>Jokers available: {quizData.joker - usedJoker}</p>
                                                    </div>

                                                    <button className={`border-0  ${buttonClicked ? 'animate' : ''}`}
                                                        onClick={() => handleSubmit(false)}
                                                        disabled={isSubmit ?  true : false}
                                                    >Submit</button>

                                                </div>
                                            </div>
                                            <div className="col-6 col-md-2 text-center quiz_user order-2 order-md-3">
                                                <h6>Your Opponent</h6>
                                                <div className='quiz_user_image'>
                                                    <div className="choose_quiz_user_image_circle d-flex justify-content-center align-items-center m-auto">
                                                        { activeUser !== "Ada" ? <img src="/new-business/ada.webp" className='position-relative z-3 pt-3' width={80} alt="Ada" />
                                                            : <img src="/new-business/ommo.webp" className='position-relative z-3 pt-3' width={80} alt="Ada" /> }
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        <button className='border-0 ada_btn'>{ activeUser === "Ada" ? "Ommo" : "Ada"}</button>
                                                    </div>
                                                </div>
                                                <p>5 points</p>
                                                <div className='points_line d-flex flex-wrap justify-content-center align-items-center m-auto gap-1'>
                                                    {botAnswers?.length > 0 && botAnswers?.map((answer, index) => (
                                                        <div key={index} className={answer ? 'right_line' : 'wrong_line'}></div>
                                                    ))}
                                                    {[1,2,3,4,5,6,7,8,9,10].splice(botAnswers?.length)?.map((answer, index) => (
                                                        <div key={index}></div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Modal className='start_quiz_modal' show={show} centered onHide={handleClose}>
                    <Modal.Body className='w-100 h-100'>
                        {isCorrectAnswer ? <div className='fadeIn_scale_up_animation'>
                            <img className='d-flex m-auto finish-line-cuate' src="/new-business/finish-line-cuate.webp" alt="" />
                            <div className='correct_box d-flex justify-content-center align-items-center m-auto'>
                                <div className='text-center'>
                                    <img className='d-flex m-auto success_check' src="/new-business/right.webp" alt="" />
                                    <h3>Correct!</h3>
                                    <p>You earned {questionPoint} point</p>
                                </div>
                            </div>
                        </div> :
                        <div className='fadeIn_scale_up_animation'>
                            <img className='d-flex m-auto incorrect_img' width={230} height={224} src="/new-business/incorrect2.webp" alt="" />
                            <div className='correct_box incorrect_box d-flex justify-content-center align-items-center m-auto'>
                                <div className='text-center'>
                                    <img className='d-flex m-auto success_check' width={78} height={78} src="/new-business/incorrect-icon.webp" alt="" />
                                    <h3>Incorrect!</h3>
                                    <p>Your opponent gained double points</p>
                                </div>
                            </div>
                        </div>}
                    </Modal.Body>
                </Modal>
            </main>
            <Footer />
        </>
    );
};

export default BusinessQuiz
