import React from 'react';
import ReactApexChart from 'react-apexcharts';

const GaugeChart = () => {
    const options = {
        series: [44, 55, 41],
        chart: {
            type: 'donut',
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                offsetY: 10,
                donut: {
                    size: '80%', // Size of the donut hole
                },
            },
        },
        colors: ['#D76B66', '#F6CF7D', '#75C57F'], // Custom colors for each slice
        grid: {
            padding: {
                bottom: -80,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
        dataLabels: {
            enabled: false, // Disable data labels
        },
        stroke: {
            show: true,
            width: 4,
            colors: ['#fff'], // White border around each segment
        },
        xaxis: {
            labels: {
                show: false, // Hide X-axis labels
            },
        },
        yaxis: {
            labels: {
                show: false, // Hide Y-axis labels
            },
        },
        annotations: {
            position: 'front',
            yaxis: [
                {
                    y: 0, // Position of the center text (you can adjust this for alignment)
                    y2: 0,
                    borderColor: 'transparent', // Optional: hides any border around the text
                    label: {
                        text: 'Center Text', // Text to display in the center
                        style: {
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: '#333', // Color of the text
                        },
                    },
                },
            ],
        },
    };

    return (
        <div id="chart">
            <div className='position-relative speed-meter'>
                <ReactApexChart options={options} series={options.series} type="donut" height={350} />
            </div>
        </div>
    );
};

export default GaugeChart;
