import React, { useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import { CourseContext } from "../../context/CourseContext";

function BusinessFactsDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const serverURL = getServerURL();
    const { getTrailsTotalPoint } = useContext(CourseContext);

    const [facts, setFacts] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleClose = () => setShow(false);

    useEffect(() => {
        getTrailsTotalPoint()
        if (location.state && location.state.facts) {
            setFacts(location.state.facts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNext = () => {
        const latestIndex = currentIndex + 1;
        if (latestIndex === facts.length) {
            setIsDisabled(true);
            setShow(true);
            handleSubmit();
        } else {
            setCurrentIndex(latestIndex);
        }
    };


    console.log("location",location.state)
    const handleSubmit = async () => {
        await api.postWithToken(`${serverURL}trails/step-progress/`, {
            trail_step: 5,
            status: "completed",
            points_earned: 5,
            score: 100,
            trail_id: location?.state?.trailId,
        });
        await api.postWithToken(`${serverURL}trails/point-history/`, {
            activity_type: "facts-to-know-completed",
            activity: "Facts to Know Completed",
            point_gained: 5,
            point_lost: 0,
        });
        setTimeout(() => {
            navigate("/trail/what-did-you-learn", {
                state: {
                    step: true,
                    trailId: location?.state?.trailId,
                    name: location?.state?.name,
                },
            });
        }, 3000);
    };

    return (
        <>
            <Header />
            <PointsHeader to="/trail/points" />
            <section className="py-5">
                <div className="container">
                    <div className="row facts_details_step">
                        <div className="col-md-6">
                            <img
                                src={facts[currentIndex]?.image}
                                className="w-100 h-100"
                                alt=""
                            />
                        </div>
                        <div className="col-md-6 mt-4 mt-md-0">
                            <div className="ps-xl-4">
                                <h6>Top 10 Things to Know</h6>
                                <h3>
                                    {currentIndex + 1}.
                                    {facts[currentIndex]?.title}
                                </h3>
                                <p>{facts[currentIndex]?.description}</p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end pt-4 pb-md-5">
                        <button
                            className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 mt-md-4"
                            onClick={handleNext}
                            disabled={isDisabled}
                        >
                            {facts.length - 1 === currentIndex
                                ? "Submit"
                                : "Next"}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={22}
                                height={14}
                                viewBox="0 0 22 14"
                                fill="none"
                                className="ms-5"
                            >
                                <path
                                    d="M1 6.99854H21L16 1"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M21 6.99805L16 12.9995"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </section>
            <Modal
                className="congratulations_modal_custom"
                show={show}
                centered
                size="lg"
                onHide={handleClose}
            >
                <Modal.Body>
                    <div className="text-center">
                        <div className="position-relative correct_answers d-flex justify-content-center m-auto">
                            <img
                                src="/new-business/points.webp"
                                className="m-auto d-flex justify-content-center"
                                alt=""
                            />
                            <h5>5</h5>
                        </div>
                        <h3>Congratulations!</h3>
                        <p>+5 Points for Completing a Things to Know!</p>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    );
}

export default BusinessFactsDetails;
