import React from 'react';
import BusinessSidebar from '../components/BusinessSidebar'
import AdminHeader from '../layout/AdminHeader';
import Footer from '../layout/Footer';
import GaugeChart from '../components/NewBusiness/GaugeChart';
import DoughnutChart from '../components/DoughnutChart';
import EmployeeTraining from '../components/NewBusiness/EmployeeTraining';

const ComplianceReportingDashboard = () => {

    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <BusinessSidebar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className='d-md-flex justify-content-between align-items-center gap-3 pt-5 pb-0 pb-md-5'>
                                            <h2 className="text-black fw-600 text-start">
                                                Compliance Reporting Dashboard
                                            </h2>
                                        </div>
                                        <div className="row g-4 pb-5">
                                            <div className="col-12 col-lg-6">
                                                <div className="card border-0">
                                                    <div className="card-header bg-transparent border-secondary d-flex align-items-center py-4 position-relative border-0">
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Overall Compliance Score
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-4 compliance-details">
                                                        <div className="overflow-auto mb-4">
                                                            <div className="barchart">
                                                                <GaugeChart />
                                                            </div>
                                                        </div>
                                                        <h3>Your Compliance Score is Good</h3>
                                                        <h4>Last Month Score: <span>C</span></h4>
                                                        <h5>Trend: <span>Improving <img src="/new-business/improving.png" width={20} alt="arrow" /></span></h5>
                                                        <button className='d-flex m-auto mt-4'>
                                                            See More Details
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card border-0 mt-4">
                                                    <div className="card-header bg-transparent border-secondary d-flex align-items-center py-4 position-relative border-0 pb-0">
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Regulatory Compliance & Risk Management
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-4 pt-0 regulatory-compliance">
                                                        <div className="progress-main-2 mt-4">
                                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    GDPR Compliance
                                                                </p>
                                                            </div>
                                                            <div className='d-flex align-items-center gap-3 mb-4'>
                                                                <div
                                                                    className="progress progress-height01"
                                                                    role="progressbar"
                                                                    aria-label="Basic example"
                                                                    aria-valuenow={0}
                                                                    aria-valuemin={0}
                                                                    aria-valuemax={100}
                                                                >
                                                                    <div className="progress-bar progress01" />
                                                                </div>
                                                                <span className='progress-number'>92%</span>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                                <p className="text-muted fw-400 fs-7 mb-0">Cybersecurity Training</p>
                                                                <p className="mb-0 error-line">
                                                                    Needs Improvement
                                                                </p>
                                                            </div>
                                                            <div className='d-flex align-items-center gap-3 mb-4'>
                                                                <div
                                                                    className="progress progress-height01"
                                                                    role="progressbar"
                                                                    aria-label="Basic example"
                                                                    aria-valuenow={0}
                                                                    aria-valuemin={0}
                                                                    aria-valuemax={100}
                                                                >
                                                                    <div className="progress-bar progress02" />
                                                                </div>
                                                                <span className='progress-number'>92%</span>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                                <p className="text-muted fw-400 fs-7 mb-0">AI Literacy Training</p>
                                                                <p className="mb-0 error-line">
                                                                    High Risk
                                                                </p>
                                                            </div>
                                                            <div className='d-flex align-items-center gap-3 mb-4'>
                                                                <div
                                                                    className="progress progress-height01"
                                                                    role="progressbar"
                                                                    aria-label="Basic example"
                                                                    aria-valuenow={0}
                                                                    aria-valuemin={0}
                                                                    aria-valuemax={100}
                                                                >
                                                                    <div className="progress-bar progress03" />
                                                                </div>
                                                                <span className='progress-number'>92%</span>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                                <p className="text-muted fw-400 fs-7 mb-0">ESG (Environmental, Social, Governance)</p>
                                                            </div>
                                                            <div className='d-flex align-items-center gap-3'>
                                                                <div
                                                                    className="progress progress-height01"
                                                                    role="progressbar"
                                                                    aria-label="Basic example"
                                                                    aria-valuenow={0}
                                                                    aria-valuemin={0}
                                                                    aria-valuemax={100}
                                                                >
                                                                    <div className="progress-bar progress04 environmental-color" />
                                                                </div>
                                                                <span className='progress-number'>40%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card border-0">
                                                    <div className="card-header bg-transparent border-secondary d-flex align-items-center py-4 position-relative border-0 pb-0">
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Employee Training Completion
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-4 pt-0 regulatory-compliance">
                                                        <EmployeeTraining />
                                                    </div>
                                                </div>
                                                <div className="card border-0 mt-4">
                                                    <div className="card-header bg-transparent border-secondary d-flex align-items-center py-4 position-relative border-0 pb-0">
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Leaderboard
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-4 pt-0">
                                                        <table class="table text-center leaderboard-table m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Rank</th>
                                                                    <th>Employee Name</th>
                                                                    <th>Department</th>
                                                                    <th width="100">Compliance Score (%)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='text-center'>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>John Doe</td>
                                                                    <td>IT</td>
                                                                    <td>98%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>2</td>
                                                                    <td>Sarah Lee</td>
                                                                    <td>HR</td>
                                                                    <td>94%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>3</td>
                                                                    <td>Carlos Smith</td>
                                                                    <td>Finance</td>
                                                                    <td>91%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>4</td>
                                                                    <td>Maria Johnson</td>
                                                                    <td>Sales</td>
                                                                    <td>88%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>5</td>
                                                                    <td>Michael Brown	</td>
                                                                    <td>Marketing</td>
                                                                    <td>85%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default ComplianceReportingDashboard
