import React, { useState } from 'react'
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import "../../styles/newbusiness.css"
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../helper/api';
import { getServerURL } from '../../helper/envConfig';

const RequestOnlineTraining = () => {

     const serverURL = getServerURL()

    const [formData, setFormData] = useState({
            full_name: "",
            email: "",
            company: "",
            topic: "",
            preferred_format: "online",
            date: "",
            participants: 0,
            objective: "",
            comment: "",
            address: "",
            location: "",
            city: "",
            country: "germany",
            platform: "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!formData.full_name) {
                toast.error("Full name is required!");
                return;
            }
            if (!formData.email) {
                toast.error("Email is required!");
                return;
            }
            if (!formData.company) {
                toast.error("Company is required!");
                return;
            }
            if (!formData.topic) {
                toast.error("Topic is required!");
                return;
            }
            if (!formData.date) {
                toast.error("Date is required!");
                return;
            }
            if (formData.participants <= 0) {
                toast.error("Participants count must be greater than 0!");
                return;
            }
            if (!formData.objective) {
                toast.error("Objective is required!");
                return;
            }

            if( formData.preferred_format === "onsite") {
                if (!formData.address) {
                    toast.error("Address is required!");
                    return;
                }
                if (!formData.location) {
                    toast.error("Location is required!");
                    return;
                }
                if (!formData.city) {
                    toast.error("City is required!");
                    return;
                }
                if (!formData.country) {
                    toast.error("Country is required!");
                    return;
                }
            }
            if( formData.preferred_format === "online") {
                if (!formData.platform) {
                    toast.error("Please enter your preferred platform!");
                    return;
                }
            }
            const res = await api.post(serverURL + `users/training-request/`, formData)
                if (res.data.success) {
                    toast.success(res.data.message);
                    setFormData({
                        full_name: "",
                        email: "",
                        company: "",
                        topic: "",
                        preferred_format: "online",
                        date: "",
                        participants: 0,
                        objective: "",
                        comment: "",
                        address: "",
                        location: "",
                        city: "",
                        country: "germany",
                        platform: "",
                    })
                }
        } catch (error) {
            console.log(error)
        }


    };


    return (
        <>
            <Header />
            <main className='business_public_spaces'>
                <section className='business_case_study position-relative'>
                    <div className="container">
                        <div className="row justify-content-center w-100 w-sm-100 pt-4 pt-lg-0">
                            <div className="col-12 col-xl-10 d-flex justify-content-center align-items-center order-2 order-md-1">
                                <div className='right_side_information request-training request-online-training'>
                                    <h6>Request an Online or Onsite Training</h6>
                                    <div className='mt-4 mt-md-4 mt-lg-5'>
                                        <Form className='row justify-content-center g-4'>
                                            <div className="col-12 col-md-6">
                                                <h3>Requester Information</h3>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Full Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter your full name" name={"full_name"} value={formData.full_name} onChange={handleChange} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label > Email Address</Form.Label >
                                                    <Form.Control type="email" placeholder="Enter your preferred platform" name='email' value={formData.email}  onChange={handleChange} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Company / Organization</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter your company name" name="company" value={formData.company} onChange={handleChange} />
                                                </Form.Group>
                                                <h3>Training Details</h3>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Training Topic</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter your training"  name="topic" value={formData.topic} onChange={handleChange}  />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Preferred Format </Form.Label>
                                                    <Form.Select aria-label="Default select example" value={formData.preferred_format}
                                                        onChange={(e)=> {
                                                            setFormData({...formData, preferred_format: e.target.value })
                                                        }}>
                                                        <option value="online">Online</option>
                                                        <option value="onsite">Onsite</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Preferred Date(s) </Form.Label>
                                                    <Form.Control type="date" placeholder="Enter your training" name="date" value={formData?.date} onChange={handleChange} />
                                                </Form.Group>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                {formData.preferred_format === "onsite" &&
                                                    <>
                                                        <h3>Location Details</h3>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                <Form.Control type="text" placeholder="Enter your street address"  name="address" value={formData?.address} onChange={handleChange}  />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                <Form.Control type="text" placeholder="Enter your Apt, suite, etc" name="location" value={formData?.location} onChange={handleChange} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                <Form.Control type="text" placeholder="Enter your city" name="city" value={formData?.city} onChange={handleChange} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                <Form.Select aria-label="Default select example" value={formData?.country}
                                                                onChange={(e)=> {
                                                                    setFormData({...formData, country: e.target.value })
                                                                }} >
                                                                    <option value="afghanistan">Afghanistan</option>
                                                                    <option value="albania">Albania</option>
                                                                    <option value="algeria">Algeria</option>
                                                                    <option value="american_samoa">American Samoa</option>
                                                                    <option value="andorra">Andorra</option>
                                                                    <option value="angola">Angola</option>
                                                                    <option value="anguilla">Anguilla</option>
                                                                    <option value="antigua_and_barbuda">Antigua and Barbuda</option>
                                                                    <option value="argentina">Argentina</option>
                                                                    <option value="armenia">Armenia</option>
                                                                    <option value="aruba">Aruba</option>
                                                                    <option value="australia">Australia</option>
                                                                    <option value="austria">Austria</option>
                                                                    <option value="azerbaijan">Azerbaijan</option>
                                                                    <option value="bahamas">Bahamas</option>
                                                                    <option value="bahrain">Bahrain</option>
                                                                    <option value="bangladesh">Bangladesh</option>
                                                                    <option value="barbados">Barbados</option>
                                                                    <option value="belarus">Belarus</option>
                                                                    <option value="belgium">Belgium</option>
                                                                    <option value="belize">Belize</option>
                                                                    <option value="benin">Benin</option>
                                                                    <option value="bermuda">Bermuda</option>
                                                                    <option value="bhutan">Bhutan</option>
                                                                    <option value="bolivia">Bolivia</option>
                                                                    <option value="bosnia_and_herzegovina">Bosnia and Herzegovina</option>
                                                                    <option value="botswana">Botswana</option>
                                                                    <option value="brazil">Brazil</option>
                                                                    <option value="british_indian_ocean_territory">British Indian Ocean Territory</option>
                                                                    <option value="brunei_darussalam">Brunei Darussalam</option>
                                                                    <option value="bulgaria">Bulgaria</option>
                                                                    <option value="burkina_faso">Burkina Faso</option>
                                                                    <option value="burundi">Burundi</option>
                                                                    <option value="cambodia">Cambodia</option>
                                                                    <option value="cameroon">Cameroon</option>
                                                                    <option value="canada">Canada</option>
                                                                    <option value="cape_verde">Cape Verde</option>
                                                                    <option value="cayman_islands">Cayman Islands</option>
                                                                    <option value="central_african_republic">Central African Republic</option>
                                                                    <option value="chad">Chad</option>
                                                                    <option value="chile">Chile</option>
                                                                    <option value="china">China</option>
                                                                    <option value="christmas_island">Christmas Island</option>
                                                                    <option value="cocos_keeling_islands">Cocos (Keeling) Islands</option>
                                                                    <option value="colombia">Colombia</option>
                                                                    <option value="comoros">Comoros</option>
                                                                    <option value="congo">Congo</option>
                                                                    <option value="congo_democratic_republic">Congo (Democratic Republic)</option>
                                                                    <option value="cook_islands">Cook Islands</option>
                                                                    <option value="costa_rica">Costa Rica</option>
                                                                    <option value="croatia">Croatia</option>
                                                                    <option value="cuba">Cuba</option>
                                                                    <option value="cyprus">Cyprus</option>
                                                                    <option value="czech_republic">Czech Republic</option>
                                                                    <option value="cote_divoire">Côte d'Ivoire</option>
                                                                    <option value="denmark">Denmark</option>
                                                                    <option value="djibouti">Djibouti</option>
                                                                    <option value="dominica">Dominica</option>
                                                                    <option value="dominican_republic">Dominican Republic</option>
                                                                    <option value="ecuador">Ecuador</option>
                                                                    <option value="egypt">Egypt</option>
                                                                    <option value="el_salvador">El Salvador</option>
                                                                    <option value="equatorial_guinea">Equatorial Guinea</option>
                                                                    <option value="eritrea">Eritrea</option>
                                                                    <option value="estonia">Estonia</option>
                                                                    <option value="ethiopia">Ethiopia</option>
                                                                    <option value="falkland_islands_malvinas">Falkland Islands (Malvinas)</option>
                                                                    <option value="faroe_islands">Faroe Islands</option>
                                                                    <option value="fiji">Fiji</option>
                                                                    <option value="finland">Finland</option>
                                                                    <option value="france">France</option>
                                                                    <option value="french_guiana">French Guiana</option>
                                                                    <option value="french_polynesia">French Polynesia</option>
                                                                    <option value="french_southern_territories">French Southern Territories</option>
                                                                    <option value="gabon">Gabon</option>
                                                                    <option value="gambia">Gambia</option>
                                                                    <option value="georgia">Georgia</option>
                                                                    <option value="germany" selected>Germany</option>
                                                                    <option value="ghana">Ghana</option>
                                                                    <option value="gibraltar">Gibraltar</option>
                                                                    <option value="greece">Greece</option>
                                                                    <option value="greenland">Greenland</option>
                                                                    <option value="grenada">Grenada</option>
                                                                    <option value="guadeloupe">Guadeloupe</option>
                                                                    <option value="guam">Guam</option>
                                                                    <option value="guatemala">Guatemala</option>
                                                                    <option value="guernsey">Guernsey</option>
                                                                    <option value="guinea">Guinea</option>
                                                                    <option value="guinea_bissau">Guinea-Bissau</option>
                                                                    <option value="guyana">Guyana</option>
                                                                    <option value="haiti">Haiti</option>
                                                                    <option value="heard_island_and_mcdonald_islands">Heard Island and McDonald Islands</option>
                                                                    <option value="honduras">Honduras</option>
                                                                    <option value="hong_kong">Hong Kong</option>
                                                                    <option value="hungary">Hungary</option>
                                                                    <option value="iceland">Iceland</option>
                                                                    <option value="india">India</option>
                                                                    <option value="indonesia">Indonesia</option>
                                                                    <option value="iran">Iran</option>
                                                                    <option value="iraq">Iraq</option>
                                                                    <option value="ireland">Ireland</option>
                                                                    <option value="isle_of_man">Isle of Man</option>
                                                                    <option value="israel">Israel</option>
                                                                    <option value="italy">Italy</option>
                                                                    <option value="jamaica">Jamaica</option>
                                                                    <option value="japan">Japan</option>
                                                                    <option value="jersey">Jersey</option>
                                                                    <option value="jordan">Jordan</option>
                                                                    <option value="kazakhstan">Kazakhstan</option>
                                                                    <option value="kenya">Kenya</option>
                                                                    <option value="kiribati">Kiribati</option>
                                                                    <option value="kuwait">Kuwait</option>
                                                                    <option value="kyrgyzstan">Kyrgyzstan</option>
                                                                    <option value="lao_peoples_democratic_republic">Lao People's Democratic Republic</option>
                                                                    <option value="latvia">Latvia</option>
                                                                    <option value="lebanon">Lebanon</option>
                                                                    <option value="lesotho">Lesotho</option>
                                                                    <option value="liberia">Liberia</option>
                                                                    <option value="libya">Libya</option>
                                                                    <option value="liechtenstein">Liechtenstein</option>
                                                                    <option value="lithuania">Lithuania</option>
                                                                    <option value="luxembourg">Luxembourg</option>
                                                                    <option value="macao">Macao</option>
                                                                    <option value="macedonia">Macedonia</option>
                                                                    <option value="madagascar">Madagascar</option>
                                                                    <option value="malawi">Malawi</option>
                                                                    <option value="malaysia">Malaysia</option>
                                                                    <option value="maldives">Maldives</option>
                                                                    <option value="mali">Mali</option>
                                                                    <option value="malta">Malta</option>
                                                                    <option value="marshall_islands">Marshall Islands</option>
                                                                    <option value="martinique">Martinique</option>
                                                                    <option value="mauritania">Mauritania</option>
                                                                    <option value="mauritius">Mauritius</option>
                                                                    <option value="mayotte">Mayotte</option>
                                                                    <option value="mexico">Mexico</option>
                                                                    <option value="micronesia_federated_states_of">Micronesia (Federated States of)</option>
                                                                    <option value="moldova">Moldova</option>
                                                                    <option value="monaco">Monaco</option>
                                                                    <option value="mongolia">Mongolia</option>
                                                                    <option value="montenegro">Montenegro</option>
                                                                    <option value="montserrat">Montserrat</option>
                                                                    <option value="morocco">Morocco</option>
                                                                    <option value="mozambique">Mozambique</option>
                                                                    <option value="myanmar">Myanmar</option>
                                                                    <option value="namibia">Namibia</option>
                                                                    <option value="nauru">Nauru</option>
                                                                    <option value="nepal">Nepal</option>
                                                                    <option value="netherlands">Netherlands</option>
                                                                    <option value="new_caledonia">New Caledonia</option>
                                                                    <option value="new_zealand">New Zealand</option>
                                                                    <option value="nicaragua">Nicaragua</option>
                                                                    <option value="niger">Niger</option>
                                                                    <option value="nigeria">Nigeria</option>
                                                                    <option value="niue">Niue</option>
                                                                    <option value="norfolk_island">Norfolk Island</option>
                                                                    <option value="north_korea">North Korea</option>
                                                                    <option value="northern_mariana_islands">Northern Mariana Islands</option>
                                                                    <option value="norway">Norway</option>
                                                                    <option value="oman">Oman</option>
                                                                    <option value="pakistan">Pakistan</option>
                                                                    <option value="palau">Palau</option>
                                                                    <option value="palestinian_territory">Palestinian Territory</option>
                                                                    <option value="panama">Panama</option>
                                                                    <option value="papua_new_guinea">Papua New Guinea</option>
                                                                    <option value="paraguay">Paraguay</option>
                                                                    <option value="peru">Peru</option>
                                                                    <option value="philippines">Philippines</option>
                                                                    <option value="pitcairn_islands">Pitcairn Islands</option>
                                                                    <option value="poland">Poland</option>
                                                                    <option value="portugal">Portugal</option>
                                                                    <option value="puerto_rico">Puerto Rico</option>
                                                                    <option value="qatar">Qatar</option>
                                                                    <option value="reunion">Réunion</option>
                                                                    <option value="romania">Romania</option>
                                                                    <option value="russia">Russia</option>
                                                                    <option value="rwanda">Rwanda</option>
                                                                    <option value="saint_barthelemy">Saint Barthélemy</option>
                                                                    <option value="saint_helena">Saint Helena</option>
                                                                    <option value="saint_kitts_and_nevis">Saint Kitts and Nevis</option>
                                                                    <option value="saint_lucia">Saint Lucia</option>
                                                                    <option value="saint_martin">Saint Martin</option>
                                                                    <option value="sint_maarten">Sint Maarten</option>
                                                                    <option value="saint_pierre_and_miquelon">Saint Pierre and Miquelon</option>
                                                                    <option value="saint_vincent_and_the_grenadines">Saint Vincent and the Grenadines</option>
                                                                    <option value="samoa">Samoa</option>
                                                                    <option value="san_marino">San Marino</option>
                                                                    <option value="sao_tome_and_principe">Sao Tome and Principe</option>
                                                                    <option value="saudi_arabia">Saudi Arabia</option>
                                                                    <option value="senegal">Senegal</option>
                                                                    <option value="serbia">Serbia</option>
                                                                    <option value="seychelles">Seychelles</option>
                                                                    <option value="sierra_leone">Sierra Leone</option>
                                                                    <option value="singapore">Singapore</option>
                                                                    <option value="slovakia">Slovakia</option>
                                                                    <option value="slovenia">Slovenia</option>
                                                                    <option value="solomon_islands">Solomon Islands</option>
                                                                    <option value="somalia">Somalia</option>
                                                                    <option value="south_africa">South Africa</option>
                                                                    <option value="south_georgia_and_south_sandwich_islands">South Georgia and the South Sandwich Islands</option>
                                                                    <option value="south_korea">South Korea</option>
                                                                    <option value="spain">Spain</option>
                                                                    <option value="sri_lanka">Sri Lanka</option>
                                                                    <option value="sudan">Sudan</option>
                                                                    <option value="suriname">Suriname</option>
                                                                    <option value="svalbard_and_jan_mayen">Svalbard and Jan Mayen</option>
                                                                    <option value="swaziland">Swaziland</option>
                                                                    <option value="sweden">Sweden</option>
                                                                    <option value="switzerland">Switzerland</option>
                                                                    <option value="syria">Syria</option>
                                                                    <option value="taiwan">Taiwan</option>
                                                                    <option value="tajikistan">Tajikistan</option>
                                                                    <option value="tanzania">Tanzania</option>
                                                                    <option value="thailand">Thailand</option>
                                                                    <option value="timor_leste">Timor-Leste</option>
                                                                    <option value="togo">Togo</option>
                                                                    <option value="tokelau">Tokelau</option>
                                                                    <option value="tonga">Tonga</option>
                                                                    <option value="trinidad_and_tobago">Trinidad and Tobago</option>
                                                                    <option value="tunisia">Tunisia</option>
                                                                    <option value="turkey">Turkey</option>
                                                                    <option value="turkmenistan">Turkmenistan</option>
                                                                    <option value="turks_and_caicos_islands">Turks and Caicos Islands</option>
                                                                    <option value="tuvalu">Tuvalu</option>
                                                                    <option value="uganda">Uganda</option>
                                                                    <option value="ukraine">Ukraine</option>
                                                                    <option value="united_arab_emirates">United Arab Emirates</option>
                                                                    <option value="united_kingdom">United Kingdom</option>
                                                                    <option value="united_states">United States</option>
                                                                    <option value="uruguay">Uruguay</option>
                                                                    <option value="uzbekistan">Uzbekistan</option>
                                                                    <option value="vanuatu">Vanuatu</option>
                                                                    <option value="venezuela">Venezuela</option>
                                                                    <option value="vietnam">Vietnam</option>
                                                                    <option value="wallis_and_futuna">Wallis and Futuna</option>
                                                                    <option value="western_sahara">Western Sahara</option>
                                                                    <option value="yemen">Yemen</option>
                                                                    <option value="zambia">Zambia</option>
                                                                    <option value="zimbabwe">Zimbabwe</option>
                                                                </Form.Select>
                                                        </Form.Group>
                                                    </>
                                                }

                                                {formData.preferred_format === "online" &&
                                                    <>
                                                        <h3>Preferred Platform</h3>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Preferred Platform</Form.Label>
                                                            <Form.Control type="text" placeholder="Enter your preferred platform" name="platform" value={formData?.platform} onChange={handleChange} />
                                                        </Form.Group>
                                                    </>
                                                }
                                                <h3>Participants & Goals</h3>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Number of Participants</Form.Label>
                                                    <Form.Control type="number" placeholder="Enter your number"  name="participants" value={formData?.participants} onChange={handleChange} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Key Learning Objectives</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter your objectives" name="objective" value={formData?.objective} onChange={handleChange} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>Additional Comments (optional)</Form.Label>
                                                    <Form.Control as="textarea" rows={3} name="comment" value={formData?.comment} onChange={handleChange} />
                                                </Form.Group>
                                            </div>
                                        </Form>
                                    </div>
                                    <div className='d-flex align-items-center gap-3 pb-3 pb-lg-5'>
                                        <button className='w-auto' onClick={handleSubmit}>
                                            Request Training
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default RequestOnlineTraining
