import { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Card, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { decryptFromStoreLoc } from "../../helper/localStore";
import api from "../../helper/api";
import { getImageBaseUrl, getServerURL } from "../../helper/envConfig";
import { encryptAndStore } from "../../helper/sessionStore";

function Trails() {
    const location = useLocation();
    const navigate = useNavigate();
    const serverURL = getServerURL();
    const imageUrl = getImageBaseUrl();
    const [user, setUser] = useState({});
    const [trailsData, setTrailsData] = useState([]);

    const getTrails = async () => {
        try {
            var response = await api.get(`${serverURL}courses/course-trails/`);
            if (response.data.success) {
                setTrailsData(response.data.data);
            } else {
                console.error("Error:", response.data.message);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        getTrails();
        localStorage.removeItem("caseStudyResult")
        localStorage.removeItem("caseStudy")
    }, []);

    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc("user");
        setUser(decryptedInfoResult);
    }, []);

    const handleNavigate = async (id, name) => {
        encryptAndStore("trailDetails", { name: name, trailId: id });
        navigate("/trails", {
            state: {
                name: name,
                trailId: id,
            },
        });
    };

    return (
        <>
            <Header />
            <section className="payment-flow-section">
                <div className="container">
                    <div className="available-trails pb-md-5 pt-0">
                        <h3 className="welcome-title max-width-100">Trails</h3>

                        <Row className="available-trails-cards g-3 cursor-pointer">
                            {trailsData.map((trail) => {
                                if(trail.is_active) {
                                   return ( <Col
                                        key={trail.trail_id}
                                        md={6}
                                        lg={6}
                                        xl={4}
                                        onClick={() =>
                                            handleNavigate(
                                                trail.trail_id,
                                                trail.name
                                            )
                                        }
                                    >
                                        <Card>
                                            <Card.Body>
                                                <div className="d-flex align-items-center gap-3">
                                                    <img
                                                        width={100}
                                                        height={100}
                                                        src={imageUrl + trail.icon}
                                                        alt={trail.name}
                                                    />
                                                    <div>
                                                        <h5>{trail.name}</h5>
                                                        <p>{trail.description}</p>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>)
                                }
                            })}
                        </Row>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Trails;
